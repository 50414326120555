import React from 'react';
import ga from 'utils/ga/resources';
import { isUk, isANZ } from 'utils/translateHelpers';
import config from 'utils/config';
import handHygiene from './assets/hand-hygiene.png';
import vaccConsiderations from './assets/virus-hr-considerations.png';
import vaccPolicyNI from './assets/vaccine-policy-ni.png';
import vaccPolicy from './assets/viruspolicy.png';
import encouragingStaff from './assets/encouraging-staff.png';
import requiringStaff from './assets/requiring-staff.png';
import inHouse from './assets/in-house.png';
import hygieneChecklist from './assets/hygiene-checklist.png';
import backToWork from './assets/back-to-work.png';
import maskFitting from './assets/mask-fitting.png';
import socialDistancing from './assets/social-distancing.png';
import videoBenefits from './assets/video-benefits.png';
import videoFaqs from './assets/video-faqs.png';
import videoWhatIs from './assets/video-whatis.png';

const {
	VACC_CONSIDERATIONS,
	VACC_POLICY,
	ENCOURAGING_STAFF,
	REQUIRING_STAFF,
	BACK_TO_WORK,
	HYGIENE_CHECKLIST,
	HAND_HYGIENE,
	MASK_FITTING,
	SOCIAL_DISTANCING,
} = config();

const resourcess = [
	{
		title: 'COVID-19 vaccine',
		resources: [
			{
				img: videoWhatIs,
				heading: 'Video: What is a vaccine?',
				description:
					'Watch our short video to learn more about the different types of COVID-19 vaccines.',
				link: 'https://soapbox.wistia.com/videos/XH0KK3eVQq',
				cta: 'Watch',
			},
			{
				img: videoBenefits,
				heading: 'Video: Benefits of vaccination',
				description:
					'Join our friendly expert to discover the benefits of vaccinations for you and your employees.',
				link: 'https://soapbox.wistia.com/videos/0YdPVVQAMV',
				cta: 'Watch',
			},
			...(isUk()
				? [
						{
							img: videoFaqs,
							heading: 'Video: COVID-19 vaccine FAQs',
							description:
								'Learn how the vaccine works, who’s eligible for it and much more.',
							link: 'https://soapbox.wistia.com/videos/HOjujyKMy2',
							cta: 'Watch',
						},
				  ]
				: []),
		],
	},
	...(!isANZ()
		? [
				{
					title: 'VaccTrak Resources - General',
					resources: [
						{
							img: vaccConsiderations,
							heading: 'Coronavirus vaccine: HR considerations',
							description:
								'Get expert advice and guidance on how to approach the COVID-19 vaccine in your business.',
							link: VACC_CONSIDERATIONS,
						},
						...(isUk()
							? [
									{
										img: vaccPolicy,
										heading: 'Coronavirus vaccine policy (GB)',
										description:
											'Download an expert coronavirus vaccination policy for your business.',
										link: VACC_POLICY,
									},
									{
										img: vaccPolicyNI,
										heading: 'Coronavirus vaccine policy (NI)',
										description:
											'Download an expert coronavirus vaccination policy for your business.',
										link:
											'https://pages.brighthr.com/rs/217-MIC-854/images/NI_Coronavirus_vaccine_policy.pdf',
									},
									{
										img: inHouse,
										heading: 'In-house coronavirus testing policy',
										description:
											'Download our policy template to explain who’s eligible for in-house testing, how the tests will be carried out, and what happens after a result is given.',
										link:
											'https://pages.brighthr.com/rs/217-MIC-854/images/UK_In_house_coronavirus_testing_policy.pdf',
									},
							  ]
							: [
									{
										img: vaccPolicy,
										heading: 'Coronavirus vaccine policy',
										description:
											'Download an expert coronavirus vaccination policy for your business.',
										link: VACC_POLICY,
									},
							  ]),
						{
							img: encouragingStaff,
							heading:
								'Letter: Encouraging employee uptake of the coronavirus vaccine',
							description: (
								<>
									Download a free letter template to explain why your staff{' '}
									<b>should</b> take the vaccine.
								</>
							),
							link: ENCOURAGING_STAFF,
						},
						{
							img: requiringStaff,
							heading: 'Letter: Employee requiring uptake of coronavirus vaccine',
							description: (
								<>
									Download a free letter template to explain why all your staff{' '}
									<b>need</b> to take the vaccine.
								</>
							),
							link: REQUIRING_STAFF,
						},
					],
				},
		  ]
		: []),
	{
		title: 'Back to work: Health & safety resources',
		resources: [
			...(!isANZ()
				? [
						{
							img: backToWork,
							heading: 'Back to work: Hygiene guidance',
							description:
								'Get advice on social distancing, PPE, and managing breakout areas in our hygiene guide.',
							link: BACK_TO_WORK,
						},
						{
							img: hygieneChecklist,
							heading: 'Back to work: Hygiene checklist',
							description:
								'Assess whether your business has a good level of hygiene with our in-depth checklist.',
							link: HYGIENE_CHECKLIST,
						},
				  ]
				: []),
			{
				img: handHygiene,
				heading: 'Hand hygiene poster',
				description:
					'Download and print off this hand washing poster and place it around your business as a reminder for staff.',
				link: HAND_HYGIENE,
			},
			{
				img: maskFitting,
				heading: 'Mask fitting poster',
				description:
					'It’s important that your people know how to wear masks properly in the workplace. Download this poster to get them up to speed.',
				link: MASK_FITTING,
			},
			{
				img: socialDistancing,
				heading: 'Social distancing poster',
				description:
					'Remind your staff to keep a safe two-metre distance from others with this bold and informative poster.',
				link: SOCIAL_DISTANCING,
			},
		],
	},
];

export default () => (
	<>
		<h2 className="font-bold text-xl mb-12">Essential resources</h2>
		{resourcess.map(({ title, resources }) => (
			<>
				<div className="pb-8">
					<h3 className="font-bold text-lg mb-6">{title}</h3>
					<div className="md:grid md:grid-cols-3 md:gap-4">
						{resources.map(({ heading, description, link, cta, img }) => (
							<>
								<div className="mb-4 md:mb-0 bg-white border border-brand-300 rounded">
									<img alt="" src={img || ''} className="w-full" />
									<div className="m-4">
										<div className="font-bold">{heading}</div>
										<p className="mb-3">{description}</p>
										<a
											className="text-brand-500"
											href={link}
											target="_blank"
											rel="noopener noreferrer"
											onClick={() => ga(cta || 'Download', heading)}
										>
											{cta || 'Download'}
										</a>
									</div>
								</div>
							</>
						))}
					</div>
				</div>
				<hr className="border-brand-400 last:border-0 mb-6" />
			</>
		))}
	</>
);
