import React from 'react';
import { Button, Variables } from 'bright-components';
import { Link } from 'react-router-dom';

export default () => (
	<>
		<h2 className="font-bold text-lg mb-3">Let’s begin by adding your employees...</h2>
		<p className="mb-4">
			To get the most out of VaccTrak Lite, you need to add some basic information about your
			employees. This will help you keep a secure record of who’s had the COVID-19 vaccine and
			who hasn’t.
		</p>
		<p className="mb-4">Click the button below to get started...</p>
		<Button
			as={Link}
			to="/vacctrak/add-employees"
			style={{
				boxShadow: Variables.boxShadow,
			}}
		>
			+ Add employees
		</Button>
	</>
);
