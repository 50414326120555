import React, { useState } from 'react';
import { Button, H3, FormikInput, Spacing, XingLoader } from 'bright-components';
import ga from 'utils/ga/myEmployees';
import { Formik } from 'formik';
import addEmployee from 'services/employee/addEmployee';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import FormikRow from './FormikRow';
import AlreadyAdded from './AlreadyAdded';
import AdviceTile from '../components/AdviceTile';

const SadInput = styled(FormikInput)`
	margin: 0 0.5rem !important;
	width: ${Spacing.s7} !important;
`;

const validationSchema = (num) => {
	return Yup.object().shape(
		num.reduce((acc, val, i) => {
			const index = i + 1;
			return {
				...acc,
				[`firstName${index}`]: Yup.string().label('First name').max(50).required(),
				[`lastName${index}`]: Yup.string().label('Last name').max(50).required(),
				[`email${index}`]: Yup.string().label('Email').email().required(),
			};
		}, {})
	);
};

export default ({ allEmployees, employeesAdded, employeeInfoUpdated, employeeDeleted }) => {
	const [numOfRows, setNumOfRows] = useState(Array(1).fill({}));
	const history = useHistory();

	return (
		<div className="p-6 mt-4 bg-white border-2 rounded-md border-brand-400">
			<Formik
				initialValues={{ numOfRows: 1 }}
				validationSchema={validationSchema(numOfRows)}
				onSubmit={(values, { resetForm }) => {
					const newList = [];

					Promise.all(
						numOfRows.map((v, i) => {
							const index = i + 1;
							const payload = {
								firstName: values[`firstName${index}`],
								lastName: values[`lastName${index}`],
								email: values[`email${index}`],
								vaccinationStatus:
									values[`vaccinationStatus${index}`] || 'Not Specified',
								firstVaccinationDate: values[`firstVaccinationDate${index}`],
								vaccinationDate: values[`vaccinationDate${index}`],
								boosterVaccinationDate: values[`boosterVaccinationDate${index}`],
								secondBoosterDate: values[`secondBoosterDate${index}`],
								firstVaccinationSeenProof:
									values[`firstVaccinationSeenProof${index}`],
								finalVaccinationSeenProof:
									values[`finalVaccinationSeenProof${index}`],
								notes: values[`notes${index}`],
								boosterVaccinationSeenProof:
									values[`boosterVaccinationSeenProof${index}`],
								secondBoosterVaccinationSeenProof: values[`secondBoosterVaccinationSeenProof${index}`],
							};
							return addEmployee(payload).then((newUser) => {
								newList.push(newUser);
							});
						})
					).then(() => {
						employeesAdded(newList);
						resetForm({
							values: {
								numOfRows: 1,
								vaccinationStatus1: 'Not Specified',
								firstName1: '',
								lastName1: '',
								email1: '',
								notes1: '',
							},
						});
						setNumOfRows(Array(1).fill({}));
						history.push('/vacctrak/employee-vaccination-status');
					});
				}}
			>
				{(formik) => (
					<form onSubmit={formik.handleSubmit} className="mb-8">
						<div className="flex flex-col justify-between md:flex-row">
							<div className="w-full md:w-1/2">
								<h2 className="mb-4 text-2xl font-bold">
									Add employee information
								</h2>
								<div>
									Fill out a few basic details about your employees to start
									tracking staff vaccinations.
								</div>
							</div>
							<AdviceTile ga={ga} />
						</div>
						<hr className="my-6 border-brand-400" />
						<div className="flex flex-col sm:flex-row">
							<div className="flex items-center justify-center mb-4 sm:mb-0">
								Add
								<SadInput
									min={1}
									max={10}
									type="number"
									value={formik.values.numOfRows}
									name="numOfRows"
									onBlur={() => ga('Add x employee(s)', formik.values.numOfRows)}
								/>
								employee(s)
							</div>
							<Button
								className="sm:ml-2"
								type="button"
								disabled={
									formik.values.numOfRows <= 0 || formik.values.numOfRows > 10
								}
								onClick={() => {
									ga('Update form');
									setNumOfRows(Array(formik.values.numOfRows).fill({}));
									setTimeout(() => {
										formik.setTouched({});
									}, 0);
								}}
							>
								Update form
							</Button>
						</div>
						<hr className="my-6 border-brand-400" />
						{numOfRows.map((v, i) => {
							const index = i + 1;
							return (
								<div className="mb-4" key={index}>
									<H3>Employee {index}</H3>
									<FormikRow formik={formik} index={index} />
								</div>
							);
						})}
						<Button
							className="flex"
							disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
							type="submit"
							onClick={() =>
								ga(numOfRows.length > 1 ? 'Add all employees' : 'Add employee')
							}
						>
							{formik.isSubmitting && (
								<span className="mr-2">
									<XingLoader size={15} color="white" />
								</span>
							)}
							{numOfRows.length > 1 ? 'Add all employees' : 'Add employee'}
						</Button>
					</form>
				)}
			</Formik>
			<AlreadyAdded
				allEmployees={allEmployees}
				employeeInfoUpdated={employeeInfoUpdated}
				employeeDeleted={employeeDeleted}
			/>
		</div>
	);
};
