import React, { useEffect, useState } from 'react';
import { Button } from 'bright-components';
import ga from 'utils/ga/navigation';
import { useLocation, Link, Switch, Route } from 'react-router-dom';
import getAllEmployeesVaccinated from 'services/employee/covidvaccination/getAllEmployeesFullInformation';
import updateVaccinationDetails from 'services/employee/covidvaccination/put';
import sendPass from 'services/employee/covidvaccination/sendPass';
import editEmployee from 'services/employee/editEmployee';
import deleteEmployee from 'services/employee/deleteEmployee';
import Hub from './Hub';
import EmployeeVaccinationStatus from './EmployeeVaccinationStatus';
import AddEmployees from './AddEmployees';
import Tabs from './components/Tabs';

import Layout from '../../layouts';

const setRag = ({ firstVaccinationDate, vaccinationDate, vaccinationStatus }) => {
	const today = new Date().setHours(12, 0, 0, 0);
	const dateIsPast = Date.parse(vaccinationDate) <= today;

	if (vaccinationStatus === 'Yes' || vaccinationStatus === 'Pending') {
		if (vaccinationDate && firstVaccinationDate && dateIsPast) {
			return 'Green';
		}

		return 'Amber';
	}

	return 'Red';
};
const isDateInPast = (boosterVaccinationDate) => {
	const today = new Date().setHours(12, 0, 0, 0);
	const dateIsPast = Date.parse(boosterVaccinationDate) <= today;

	if (boosterVaccinationDate && dateIsPast) {
		return true;
	}

	return false;
};

const pages = [
	{
		pathname: '/vacctrak/summary',
		title: 'VaccTrak hub',
		component: Hub,
	},
	{
		pathname: '/vacctrak/employee-vaccination-status',
		title: 'Vaccination statuses',
		component: EmployeeVaccinationStatus,
	},
	{
		pathname: '/vacctrak/add-employees',
		title: 'My employees',
		component: AddEmployees,
	},
];

const VaccTrak = () => {
	const [allEmployees, setAllEmployees] = useState([]);
	const [loading, setLoading] = useState(true);
	const { pathname } = useLocation();

	useEffect(() => {
		getAllEmployeesVaccinated().then((_allEmployees) => {
			setAllEmployees(_allEmployees.sort((a, b) => a.lastName.localeCompare(b.lastName)));
			setLoading(false);
		});
	}, []);

	const employeesInTopSection = allEmployees.filter(({ vaccinationStatus }) => {
		return vaccinationStatus === 'Yes';
	});

	const employeesPartiallyVaccinated = allEmployees.filter(({ firstVaccinationDate }) =>
		isDateInPast(firstVaccinationDate)
	);

	const employeesFullyVaccinated = allEmployees.filter(({ vaccinationDate }) =>
		isDateInPast(vaccinationDate)
	);

	const employeesBoosted = allEmployees.filter(({ boosterVaccinationDate }) =>
		isDateInPast(boosterVaccinationDate)
	);

	const employeesDoubleBoosted = allEmployees.filter(({ secondBoosterDate }) =>
		isDateInPast(secondBoosterDate)
	);

	const notVaccinatedStatuses = ['no', 'exempt', 'not specified', 'prefer not to say'];
	const employeesNotVaccinated = allEmployees.filter(({ vaccinationStatus }) => {
		return notVaccinatedStatuses.includes(vaccinationStatus.toLowerCase());
	});

	const employeesPendingStatus = allEmployees.filter(({ vaccinationStatus }) => {
		return vaccinationStatus === 'Pending';
	});

	const statusUpdated = (guid, values) => {
		const updatedEmployees = allEmployees.map((employee) => {
			if (employee.guid === guid) {
				return {
					...employee,
					...values,
					ragStatus: setRag(values),
				};
			}
			return { ...employee };
		});
		updateVaccinationDetails({ employeeGuid: guid, ...values });
		setAllEmployees(updatedEmployees);
	};

	const passSent = (guid) => {
		return sendPass(guid).then(({ sentPass }) => {
			const updatedEmployees = allEmployees.map((employee) => {
				if (employee.guid === guid) {
					return {
						...employee,
						typeOfPassSent: sentPass,
					};
				}
				return { ...employee };
			});
			setAllEmployees(updatedEmployees);
		});
	};

	const employeesAdded = (newEmployees) => {
		const updatedEmployees = [...allEmployees, ...newEmployees].map((employee) => {
			return {
				...employee,
				ragStatus: setRag(employee),
			};
		});

		setAllEmployees(updatedEmployees);
	};

	const employeeInfoUpdated = (updatedEmployee) => {
		const updatedEmployees = allEmployees.map((employee) => {
			if (employee.guid === updatedEmployee.guid) {
				return { ...employee, ...updatedEmployee };
			}
			return { ...employee };
		});

		editEmployee(updatedEmployee);
		setAllEmployees(updatedEmployees);
	};

	const employeeDeleted = (employeeGuid) => {
		const updatedEmployees = allEmployees.filter((employee) => employeeGuid !== employee.guid);

		deleteEmployee(employeeGuid);
		setAllEmployees(updatedEmployees);
	};

	return (
		<Layout big={pathname === '/vacctrak/summary'}>
			<div className="relative">
				{pathname !== '/vacctrak/add-employees' && (
					<Button
						as={Link}
						to="/vacctrak/add-employees"
						className="absolute right-0 hidden md:block"
						onClick={() => ga('Add Employees')}
					>
						+ Add employees
					</Button>
				)}
				<Tabs pages={pages} />
				<Switch>
					{pages.map(({ pathname: path, component: Page }) => (
						<Route
							key={path}
							path={path}
							render={(props) => (
								<Page
									{...props}
									employeesInTopSection={employeesInTopSection}
									employeesFullyVaccinated={employeesFullyVaccinated}
									employeesPartiallyVaccinated={employeesPartiallyVaccinated}
									allEmployees={allEmployees}
									employeesVaccinated={employeesPartiallyVaccinated}
									employeesNotVaccinatedOrPending={employeesPendingStatus.concat(
										employeesNotVaccinated
									)}
									employeesBoosted={employeesBoosted}
									employeesDoubleBoosted={employeesDoubleBoosted}
									statusUpdated={statusUpdated}
									passSent={passSent}
									employeesAdded={employeesAdded}
									employeeInfoUpdated={employeeInfoUpdated}
									employeeDeleted={employeeDeleted}
									loading={loading}
								/>
							)}
						/>
					))}
				</Switch>
			</div>
		</Layout>
	);
};

export default VaccTrak;
