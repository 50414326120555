import React from 'react';
import { Link } from 'react-router-dom';

import ga from 'utils/ga/navigation';

import { logout } from 'hooks/useAuthentication';
import config from 'utils/config';
import EssentialResources from './EssentialResources';
import Logo from './logo.svg';
import BigHeader from './big-header.svg';
import SmallHeader from './small-header.svg';
import HeaderImage from './header-image.png';

const Layout = ({ children, big = false }) => {
	const { SUPPORT_GUIDE } = config();
	return (
		<>
			<div className="absolute inset-x-0">
				<div className="bg-brand-500 w-full h-64 lg:h-32" />
				<div className="bg-brand-500 w-full h-64 lg:hidden" />
				<div className="bg-brand-500 w-full h-64 sm:hidden" />
				<img src={big ? BigHeader : SmallHeader} alt="" />
			</div>
			{big && (
				<img
					src={HeaderImage}
					className="absolute right-0 lg:mr-32 xl:mr-40 mt-24 w-2/5 hidden lg:block"
					alt=""
				/>
			)}
			<header className="relative">
				<div className="p-4 mx-auto max-w-screen-xl pb-6 md:flex justify-between">
					<Link to="/" onClick={() => ga('home')}>
						<img
							src={Logo}
							alt="VaccTrak Lite"
							className="text-white fill-current"
							style={{ width: '300px' }}
						/>
					</Link>
					<ul className="text-white flex mt-4 md:mt-0 text-center">
						<li>
							<a
								className="ml-5"
								target="_blank"
								onClick={() => ga('Support guide')}
								rel="noopener noreferrer"
								href={SUPPORT_GUIDE}
							>
								Support guide
							</a>
						</li>
						<li>
							<a
								className="ml-5"
								target="_blank"
								onClick={() => ga('Feedback')}
								rel="noopener noreferrer"
								href="https://www.surveymonkey.co.uk/r/ZVYX3J9"
							>
								Feedback
							</a>
						</li>
						<li>
							<button
								type="button"
								className="ml-5 outline-none"
								onClick={() => {
									ga('Logout');
									logout();
								}}
							>
								Logout
							</button>
						</li>
					</ul>
				</div>
			</header>
			<main className="relative my-0 mx-auto p-4 pb-24 md:pt-0 max-w-screen-xl">
				{children}
			</main>
			<div id="essential-resources" className="bg-brand-100 w-full">
				<div className="my-0 mx-auto p-4 pb-24 max-w-screen-xl pt-20">
					<EssentialResources />
				</div>
			</div>
		</>
	);
};

export default Layout;
