import { currentLocale } from 'utils/locale';

export default () => {
	const getLocale = (config) => {
		return config[currentLocale.countryCode];
	};

	return {
		TERMS_LINK: getLocale({
			en: 'https://www.brighthr.com/terms',
			ca: 'https://www.brighthr.com/ca/terms',
			au: 'https://www.brighthr.com/au/terms',
			ie: 'https://www.brighthr.com/ie/terms',
			nz: 'https://www.brighthr.com/nz/terms',
		}),
	};
};
