import http from 'utils/http';
import config from 'utils/config';

const getAllEmployees = () => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}employee`;

	return http.get(uri).then(({ data }) => {
		const allEmployees = data.map(({ id, firstName, lastName, email }) => {
			return {
				guid: id,
				firstName,
				lastName,
				email,
			};
		});

		return allEmployees;
	});
};

export default getAllEmployees;
