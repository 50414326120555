export default ({ blob, filename }) => {
	if (window.navigator.msSaveBlob) {
		return window.navigator.msSaveBlob(blob, filename);
	}

	const a = document.createElement('a');
	document.body.appendChild(a);
	a.style.display = 'none';
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = filename;
	a.click();
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}, 1000);
};
