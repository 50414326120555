import getConfig from './config';

const qs = new URLSearchParams(window.location.search);

const setSelectedValuesFromQueryString = () => {
	// This was added for cypress running locally
	if (qs.get('selectedEnv')) {
		window.localStorage.setItem('selectedEnv', qs.get('selectedEnv'));
	}
};

const mapping = {
	'vacctrak.com': 'prod',
	'www.vacctrak.com': 'prod',
	localhost: 'dev',
};

setSelectedValuesFromQueryString();

const environment = mapping[window.location.hostname] || 'sandbox';

export default () => ({
	BUILD_VERSION: process.env.BUILD_VERSION,
	environment,
	...getConfig(environment),
});
