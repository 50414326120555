import http from 'utils/http';
import config from 'utils/config';
import updateVaccinationDetails from '../covidvaccination/put';

const addEmployee = ({
	firstName,
	lastName,
	email,
	vaccinationStatus,
	firstVaccinationDate,
	vaccinationDate,
	firstVaccinationSeenProof,
	finalVaccinationSeenProof,
	boosterVaccinationSeenProof,
	boosterVaccinationDate,
	secondBoosterVaccinationSeenProof,
	secondBoosterDate,
	notes,
}) => {
	const { GLOBAL_API } = config();
	const path = `${GLOBAL_API}employee`;

	const payload = {
		firstName,
		lastName,
		email,
	};

	return http.post(path, { data: payload }).then(({ data: { id } }) => {
		updateVaccinationDetails({
			employeeGuid: id,
			vaccinationStatus,
			firstVaccinationDate,
			vaccinationDate,
			firstVaccinationSeenProof,
			finalVaccinationSeenProof,
			boosterVaccinationSeenProof,
			boosterVaccinationDate,
			secondBoosterVaccinationSeenProof,
			secondBoosterDate,
			notes,
		});

		return {
			firstName,
			lastName,
			email,
			guid: id,
			vaccinationStatus,
			firstVaccinationDate,
			vaccinationDate,
			firstVaccinationSeenProof,
			finalVaccinationSeenProof,
			boosterVaccinationSeenProof,
			boosterVaccinationDate,
			secondBoosterVaccinationSeenProof,
			secondBoosterDate,
			notes,
		};
	});
};

export default addEmployee;
