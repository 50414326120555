import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export default ({ category, action, label, nonInteraction }) => {
	ReactGA.event({
		category,
		action,
		label,
	});
	if (ReactGA4.isInitialized) {
		const ga4Params = {};
		const actionString = `${action}${label ? ` ${label}` : ''}`;

		// Chops an action string into multiple numbered action properties of 100 characters each
		// This is because GA4 requires all event parameters to be <=100 characters in length
		//
		// e.g { action: 'first 100 characters', action2: 'next 100 characters' }
		const getStrSection = (i) => actionString.substring((i - 1) * 100, i * 100);

		for (let i = 1; i <= Math.ceil(actionString.length / 100); i++) {
			ga4Params[`action${i === 1 ? '' : i}`] = getStrSection(i);
		}

		if (nonInteraction) {
			ga4Params.nonInteraction = true;
		}

		ReactGA4.event(category, ga4Params);
	}
};
