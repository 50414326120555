/* eslint-disable prefer-rest-params */
/* eslint-disable max-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
import config from 'utils/config';

const { environment } = config();

export default () => {
	if (environment === 'prod') {
		((h, o, t, j, a, r) => {
			h.hj =
				h.hj ||
				function () {
					(h.hj.q = h.hj.q || []).push(arguments);
				};
			h._hjSettings = { hjid: 2316983, hjsv: 6 };
			a = o.getElementsByTagName('head')[0];
			r = o.createElement('script');
			r.async = 1;
			r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
			a.appendChild(r);
		})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
	}
};

/* eslint-enable prefer-rest-params */
/* eslint-enable max-params */
/* eslint-enable no-underscore-dangle */
/* eslint-enable prefer-destructuring */
/* eslint-enable func-names */
