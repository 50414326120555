import React, { useState, useEffect, useRef } from 'react';
import ChevronDown from 'bright-components/dist/components/Icons/ChevronDown';
import { localeObject, setNewLocale, currentLocale } from 'utils/locale';
import Flag from '../Flag';

export default () => {
	const [showDropDown, setShowDropDown] = useState(false);
	const pickerRef = useRef();

	useEffect(() => {
		const handleClick = (e) => {
			if (pickerRef.current.contains(e.target)) return;
			setShowDropDown(false);
		};

		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return (
		<div className="relative self-center lg:mr-8" ref={pickerRef}>
			<button
				type="button"
				className="flex items-center text-brand-500 focus:outline-none"
				onClick={() => setShowDropDown(!showDropDown)}
			>
				<Flag countryCode={currentLocale.countryCode} />
				{currentLocale.name}
				<ChevronDown size={15} className="ml-2" />
			</button>

			{showDropDown && (
				<ul className="absolute right-0 py-2 mt-1 bg-white rounded-md text-brand-500 w-48 border border-black">
					{Object.values(localeObject)
						.filter(({ countryCode }) => countryCode !== currentLocale.countryCode)
						.map(({ countryCode, name }) => (
							<button
								key={countryCode}
								type="button"
								className="flex items-center px-2 py-1 rounded focus:outline-none w-full hover:bg-brand-100"
								onClick={() => {
									setNewLocale(countryCode);
									setShowDropDown(false);
								}}
							>
								<Flag countryCode={countryCode} />
								{name}
							</button>
						))}
				</ul>
			)}
		</div>
	);
};
