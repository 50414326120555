import React, { useState } from 'react';
import { Pill, Button, Colors, ButtonGroup, Snackbar } from 'bright-components';
import { Formik } from 'formik';
import classnames from 'classnames';
import Pencil from 'bright-components/dist/components/Icons/Pencil';
import ExpandoBar from '../../components/ExpandoBar';
import { VaccineStatus, VaccineInfo } from '../../components/VaccineFields';

const passStatusWording = ({ values }) => {
	if (!values.firstVaccinationDate0) return 'First vaccination date needed';
	if (values.typeOfPassSent0) return `${values.typeOfPassSent0} pass sent`;
	return 'No pass sent';
};

const sendPassWording = ({ typeOfPassSent0, ragStatus0 }) => {
	const newPassSendable =
		!typeOfPassSent0 ||
		(typeOfPassSent0 === 'Partial' && ragStatus0 === 'Green') ||
		(typeOfPassSent0 === 'Full' && ragStatus0 === 'Amber');

	if (newPassSendable) return 'Send';
	return 'Resend';
};

const VaccForm = ({ employee, statusUpdated, passSent, ga }) => {
	const notVaccinatedStatuses = ['no', 'exempt', 'not specified', 'prefer not to say'];
	const notVaccinated = notVaccinatedStatuses.includes(employee.vaccinationStatus.toLowerCase());
	const [editing, setEditing] = useState(notVaccinated);
	const [sendingPass, setsendingPass] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const originallyVaccinated = employee.vaccinationStatus === 'Yes';
	const editingInVaccinatedSection = editing && originallyVaccinated;
	const editingInOtherSection = editing && !originallyVaccinated;

	return (
		<li className="p-3 even:bg-brand-100">
			<Formik
				enableReinitialize
				initialValues={{
					guid: employee.guid,
					vaccinationStatus0: employee.vaccinationStatus,
					firstVaccinationDate0: employee.firstVaccinationDate,
					vaccinationDate0: employee.vaccinationDate,
					firstVaccinationSeenProof0: employee.firstVaccinationSeenProof,
					finalVaccinationSeenProof0: employee.finalVaccinationSeenProof,
					boosterVaccinationDate0: employee.boosterVaccinationDate,
					boosterVaccinationSeenProof0: employee.boosterVaccinationSeenProof,
					secondBoosterDate0: employee.secondBoosterDate,
					secondBoosterVaccinationSeenProof0: employee.secondBoosterVaccinationSeenProof,
					notes0: employee.notes ?? '',
					showNotes0: !!employee.notes,
					typeOfPassSent0: employee.typeOfPassSent,
					ragStatus0: employee.ragStatus,
				}}
				onSubmit={(values) => {
					statusUpdated(employee.guid, {
						vaccinationStatus: values.vaccinationStatus0,
						firstVaccinationDate: values.firstVaccinationDate0,
						vaccinationDate: values.vaccinationDate0,
						firstVaccinationSeenProof: values.firstVaccinationSeenProof0,
						finalVaccinationSeenProof: values.finalVaccinationSeenProof0,
						boosterVaccinationSeenProof: values.boosterVaccinationSeenProof0,
						boosterVaccinationDate: values.boosterVaccinationDate0,
						secondBoosterVaccinationSeenProof: values.secondBoosterVaccinationSeenProof0,
						secondBoosterDate: values.secondBoosterDate0,
						notes: values.notes0,
					});
					setEditing(
						notVaccinatedStatuses.includes(values.vaccinationStatus0.toLowerCase())
					);
				}}
			>
				{(formik) => (
					<form onSubmit={formik.handleSubmit} data-e2e="employee-row">
						<div className="flex flex-col justify-between lg:flex-row">
							<div className="flex items-start justify-between w-full md:flex-row md:items-center lg:w-4/5">
								<div className="flex items-center md:w-1/3">
									<div
										className={classnames({
											'rounded-full w-3 h-3 mr-4': true,
											'bg-fully': employee.ragStatus === 'Green',
											'bg-partially': employee.ragStatus === 'Amber',
											'bg-red': employee.ragStatus === 'Red',
										})}
									/>
									<div data-e2e="employee-name">
										{employee.firstName} {employee.lastName}
									</div>
								</div>
								<div className="flex md:hidden">
									<Pencil
										className="cursor-pointer"
										onClick={() => {
											setEditing(true);
											ga('Edit');
										}}
										size={20}
										color={Colors.link}
									/>
								</div>
								{originallyVaccinated && (
									<div className="hidden w-2/3 md:flex">
										<div className="w-1/4" data-e2e="first-date">
											{employee.firstVaccinationDate
												? new Date(employee.firstVaccinationDate)
														.toString()
														.substring(0, 15)
												: 'Not set'}
										</div>
										<div className="w-1/4" data-e2e="second-date">
											{employee.vaccinationDate
												? new Date(employee.vaccinationDate)
														.toString()
														.substring(0, 15)
												: 'Not set'}
										</div>
										<div className="w-1/4">
											{employee.boosterVaccinationDate
												? new Date(employee.boosterVaccinationDate)
														.toString()
														.substring(0, 15)
												: 'Not set'}
										</div>
										<div className="w-1/4">
											{employee.secondBoosterDate
												? new Date(employee.secondBoosterDate)
														.toString()
														.substring(0, 15)
												: 'Not set'}
										</div>
									</div>
								)}
								{!editing && !originallyVaccinated && (
									<div className="w-1/2 mt-2 md:mt-0">Pending</div>
								)}
								{editingInOtherSection && <VaccineStatus formik={formik} ga={ga} />}
							</div>
							<div
								className="flex flex-col justify-between mt-4 lg:mt-0 sm:items-center lg:w-1/3 sm:flex-row"
								data-e2e="pass-status"
							>
								{!editing && (
									<div className="hidden w-1/3 md:flex">
										<Pencil
											className="mt-2 cursor-pointer md:m-auto"
											onClick={() => {
												setEditing(true);
												ga('Edit');
											}}
											size={20}
											color={Colors.link}
										/>
									</div>
								)}
								{originallyVaccinated && !editing && (
									<div className="flex items-center justify-between w-full md:w-2/3">
										<div className="w-1/2">{passStatusWording(formik)}</div>
										<div className="flex items-center justify-end w-1/2 lg:justify-center">
											<Button
												data-e2e="send-pass"
												type="button"
												className="flex mt-2"
												onClick={() => {
													setShowToast(true);
													setsendingPass(true);
													passSent(employee.guid).then(() =>
														setsendingPass(false)
													);
													ga(sendPassWording(formik.values));
												}}
												outline
												disabled={!formik.values.firstVaccinationDate0}
												loading={sendingPass}
											>
												{sendPassWording(formik.values)}
											</Button>
										</div>
									</div>
								)}
								{editingInVaccinatedSection && (
									<div className="flex items-center justify-between">
										{passStatusWording(formik)}
									</div>
								)}
							</div>
						</div>
						{editingInVaccinatedSection && (
							<div className="mt-4 ml-auto md:w-2/3 lg:w-4/5">
								<VaccineStatus formik={formik} ga={ga} />
							</div>
						)}
						{editing && (
							<>
								<div
									className={classnames({
										'w-full md:w-3/5 mt-4 ml-auto': true,
									})}
								>
									<VaccineInfo formik={formik} ga={ga} />
								</div>
								<ButtonGroup className="ml-auto sm:w-1/3 md:w-1/4">
									<Button
										type="submit"
										disabled={!formik.dirty}
										onClick={() => ga('Update')}
									>
										Update
									</Button>
									<Button
										outline
										onClick={() => {
											formik.handleReset();
											setEditing(notVaccinated);
										}}
										disabled={notVaccinated && !formik.dirty}
									>
										Cancel
									</Button>
								</ButtonGroup>
							</>
						)}
					</form>
				)}
			</Formik>
			<Snackbar
				className="right-0 left-auto"
				visible={showToast}
				displayTime="short"
				type="success"
				onClose={() => {
					setShowToast(false);
				}}
			>
				Pass successfully sent
			</Snackbar>
		</li>
	);
};

const Expando = ({ employees, heading, expandByDefault, statusUpdated, passSent, ga }) => {
	const [expanded, setExpanded] = useState(expandByDefault);
	const employeesHaveBeenAdded = employees.length > 0;

	return (
		<ExpandoBar
			expanded={expanded}
			onToggle={() => setExpanded(!expanded)}
			title={heading}
			icon={<Pill>{employees.length}</Pill>}
		>
			{employeesHaveBeenAdded && (
				<ul>
					<li className="flex justify-between p-3">
						<div className="flex w-2/3 md:w-full lg:w-4/5">
							<div className="w-1/3 ">Name</div>
							{(heading === 'Vaccinated employees' && (
								<div className="hidden w-2/3 md:flex">
									<div className="w-1/4">First</div>
									<div className="w-1/4">Second</div>
									<div className="w-1/4">First booster</div>
									<div className="w-1/4">Second booster</div>
								</div>
							)) || <div className="hidden md:block">Vaccinated?</div>}
						</div>
						{heading === 'Vaccinated employees' && (
							<div className="justify-center hidden lg:w-1/3 lg:flex ">
								VaccTrak pass
							</div>
						)}
					</li>
					<hr className="border-brand-400" />
					{employees.map((employee) => {
						return (
							<VaccForm
								key={employee.guid}
								employee={employee}
								statusUpdated={statusUpdated}
								passSent={passSent}
								ga={ga}
							/>
						);
					})}
				</ul>
			)}
		</ExpandoBar>
	);
};

export default Expando;
