import React from 'react';
import { Modal } from 'bright-components';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	div {
		width: 100%;
		max-width: 75rem;
		height: 100%;
		background-color: transparent;
		box-shadow: none;
		@media (max-width: 768px) {
			height: 28rem;
		}
	}
`;

export default ({ videoLink, closeModal }) => (
	<StyledModal onClick={closeModal}>
		<div>
			<iframe
				title="video"
				type="text/html"
				className="w-full"
				src={videoLink}
				frameBorder="0"
				allow="autoplay; fullscreen"
				allowFullScreen
				style={{
					height: '90%',
				}}
			/>
		</div>
		<button
			type="button"
			size={24}
			className="absolute bottom-0 w-full text-white outline-none"
			onClick={closeModal}
		>
			close video
		</button>
	</StyledModal>
);
