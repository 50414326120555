import React from 'react';
import { useLocation } from 'react-router-dom';
import ga from 'utils/ga/vaccinationStatuses';
import gaVaccEmployees from 'utils/ga/vaccinatedEmployees';
import gaOtherEmployees from 'utils/ga/otherEmployees';

import Expando from './Expando';
import GenerateReport from '../components/GenerateReport';
import AdviceTile from '../components/AdviceTile';
import ZeroState from '../components/ZeroState';

const EmployeeVaccinationStatus = ({
	allEmployees,
	employeesInTopSection,
	employeesNotVaccinatedOrPending,
	statusUpdated,
	passSent,
}) => {
	const location = useLocation();

	const employeesHaveBeenAdded = allEmployees.length > 1;

	return (
		<div className="p-6 mt-4 bg-white border-2 rounded-md border-brand-400">
			<div className="flex flex-col justify-between md:flex-row">
				<div className="flex flex-col w-full md:w-1/2">
					<div className="mb-4 mr-4">
						<h2 className="mb-4 text-2xl font-bold">Staff vaccination statuses</h2>
						<p>
							Update employee vaccination statuses in a click. Then, download a clear
							report to track the progress of vaccinations in your business.
						</p>
					</div>
					<GenerateReport onClick={() => ga('Download report')}>
						Download report
					</GenerateReport>
				</div>
				<AdviceTile ga={ga} />
			</div>
			<hr className="my-6 border-brand-400" />
			{!employeesHaveBeenAdded && (
				<div className="mb-6">
					<ZeroState />
				</div>
			)}
			<div className="">
				<Expando
					employees={employeesInTopSection}
					heading="Vaccinated employees"
					expandByDefault={location.search !== '?not-vaccinated'}
					statusUpdated={statusUpdated}
					passSent={passSent}
					ga={gaVaccEmployees}
				/>
				<Expando
					employees={employeesNotVaccinatedOrPending}
					heading="Employees with no vaccinations"
					expandByDefault={
						location.search === '?not-vaccinated' || !employeesInTopSection.length
					}
					statusUpdated={statusUpdated}
					passSent={passSent}
					ga={gaOtherEmployees}
				/>
			</div>
		</div>
	);
};

export default EmployeeVaccinationStatus;
