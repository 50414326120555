import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MyVaccTrak from '../pages/VaccTrak';

const Routes = () => (
	<Switch>
		<Route path="/vacctrak/:tab" component={MyVaccTrak} />
		<Redirect from="/vacctrak" to="/vacctrak/summary" />
		<Redirect from="/" to="/vacctrak/summary" />
		<Route path="*" to="/vacctrak/summary" />
	</Switch>
);

export default Routes;
