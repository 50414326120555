import React from 'react';
import ReactDOM from 'react-dom';
import './polyfills';
import './styles/tailwind.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import gtm from './utils/gtm';

gtm();

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
	ReactDOM.hydrate(<App />, rootElement);
} else {
	ReactDOM.render(<App />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
