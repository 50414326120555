import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import config from 'utils/config';
import Routes from './routing';
import useAuthentication from './hooks/useAuthentication';
import Landing from './pages/Landing';

const { GA_TRACKING_ID, GA4_TRACKING_ID } = config();
if (GA4_TRACKING_ID) {
	ReactGA4.initialize(GA4_TRACKING_ID);
} else {
	ReactGA4.initialize('foo', { testMode: true });
}
if (GA_TRACKING_ID) {
	ReactGA.initialize(GA_TRACKING_ID);
} else {
	ReactGA.initialize('foo', { testMode: true });
}
ReactGA.set({ dimension1: process.env.REACT_APP_BRANCH });
ReactGA.pageview(window.location.pathname + window.location.search);

const URLParams = new URLSearchParams(window.location.search);

const utm = Array.from(URLParams).reduce((acc, val) => {
	if (val[0].substr(0, 3) === 'utm') {
		return { ...acc, [val[0].substr(4)]: val[1] };
	}
	return { ...acc };
}, {});

localStorage.setItem('utm', JSON.stringify(utm));

function App() {
	const { isAuthenticated } = useAuthentication();

	if (window.location.pathname.match(/\/(en|au|nz|ie|ca)*(?:\/|$)/i)) {
		return <Landing />;
	}
	return isAuthenticated ? (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	) : null;
}

export default App;
