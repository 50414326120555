import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal, ModalBody, LinkButton } from 'bright-components';
import { localeObject, setNewLocale, currentLocale, lsLocale } from 'utils/locale';
import Flag from '../Flag';

export default () => {
	const [showModal, setShowModal] = useState(false);
	const [ipLocale, setIpLocale] = useState();

	useEffect(() => {
		if (lsLocale) return;

		return axios
			.get('https://geo-ip.netlify.app/locale.json', {
				json: true,
			})
			.then(({ data: { countryCodeLower } }) => {
				const newIpLocale = localeObject[countryCodeLower];

				if (!lsLocale && currentLocale.countryCode === countryCodeLower)
					setNewLocale(currentLocale.countryCode, false);

				setIpLocale(newIpLocale);
				setShowModal(newIpLocale && currentLocale.countryCode !== countryCodeLower);
			});
	}, []);

	return showModal ? (
		<Modal version="2">
			<ModalBody className="text-center">
				<div className="text-6xl">
					<Flag countryCode={ipLocale.countryCode} />
				</div>
				<p className="mx-4 my-4 bg-white">
					It looks like you’re visiting the BrightHR website from {ipLocale.name}. Would
					you like to visit the {ipLocale.nameShort} site?
				</p>
				<div className="mb-4">
					<Button
						onClick={() => {
							setNewLocale(ipLocale.countryCode);
							setShowModal(false);
						}}
					>
						Yes take me to the {ipLocale.nameShort} site
					</Button>
				</div>

				<div>
					<LinkButton
						className="font-bold"
						onClick={() => {
							setNewLocale(currentLocale.countryCode, false);
							setShowModal(false);
						}}
					>
						No stay on the {currentLocale.nameShort} site
					</LinkButton>
				</div>
			</ModalBody>
		</Modal>
	) : null;
};
