import React, { useState } from 'react';
import { Button, LinkButton } from 'bright-components';
import VideoModal from 'pages/components/videoModal';
import CountryPicker from 'pages/components/CountryPicker';
import CountryModal from 'pages/components/CountryModal';
import ga from 'utils/ga/getStarted';
import hotjar from 'utils/hotjar';
import { currentLocale } from 'utils/locale';

import brightLogo from './images/bright_logo.svg';
import feefoLogo from './images/feefo_platinum_service_2021_rectangle_L_sml_dark.svg';

import vaccTrackApp from './images/vacctrak_app.png';
import vaccTrackPasses from './images/vacctrak_passes.png';

import users from './images/icon-users.svg';
import checks from './images/icon-checks.svg';
import documentSvg from './images/icon-document.svg';
import documents from './images/icon-documents.svg';
import phone from './images/icon-phone.svg';
import passport from './images/icon-passport.svg';
import Quote from './images/quoteMark.svg';

import asoskynews from './images/aso-skynews.png';
import asolbc from './images/aso-lbc.png';
import asotimes from './images/aso-times.png';
import asobbcrn from './images/aso-bbcrn.png';
import asobbcrs from './images/aso-bbcrs.png';
import asobbcrwm from './images/aso-bbcrwm.png';

import vaccTrackLogoDark from './images/vacctrak_logo_dark.svg';
import vaccTrakLogo from './images/vacctrak_logo_blue.svg';
import vaccTrackScreens from './images/samvideo.jpg';

import Form from './Form';

const video = {
	'vacctrak-lite-pbs': '527373051',
	'vacctrak-lite-cgl': '527370573',
	default: '516691195',
};

const BrochureWare = () => {
	const [showVideoModal, setShowVideoModal] = useState(false);
	const { campaign } = JSON.parse(localStorage.getItem('utm'));
	hotjar();

	const isANZ = ['au', 'nz'].includes(currentLocale.countryCode);

	return (
		<>
			{showVideoModal && (
				<VideoModal
					videoLink={`https://player.vimeo.com/video/${video[campaign] || video.default}`}
					closeModal={() => setShowVideoModal(false)}
				/>
			)}
			<CountryModal />
			<div className="max-w-full overflow-x-hidden">
				<div className="relative pt-8 mb-16 bg-brand-100 md:pt-16 lg:pb-40">
					<svg
						className="bottom-0 left-0 z-20 hidden w-full h-auto lg:block lg:absolute"
						width="1440"
						height="476"
						viewBox="0 0 1440 476"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1440 0.797726V476H0V435.842C58.4568 450.732 121.918 460.5 192.6 460.5C355.675 460.5 473.554 416.438 587.627 373.798C621.883 360.993 655.796 348.316 690.486 337C809.491 298.179 903.311 277.906 992.087 258.723C1043 247.721 1092.26 237.078 1143.65 223.5C1264.61 191.541 1370.08 87.2796 1440 0.797726Z"
							fill="white"
						/>
					</svg>
					<header className="relative z-50 flex flex-col justify-between max-w-screen-xl px-6 mx-auto mb-16 md:flex-row">
						<div className="max-w-lg lg:pr-32">
							<img
								className="inline-block w-auto"
								src={vaccTrakLogo}
								alt="VaccTrak Lite"
							/>
						</div>
						<div className="flex justify-center mt-8 md:mt-0">
							<CountryPicker />
							<div className="hidden space-x-2 lg:flex lg:items-center">
								<svg
									className="current-fill"
									width="13"
									height="13"
									viewBox="0 0 13 13"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.9375 7.125H10.4375V3.5625C10.4375 3.17188 10.3633 2.80859 10.2148 2.47266C10.0664 2.12891 9.86328 1.83203 9.60547 1.58203C9.35547 1.32422 9.05859 1.12109 8.71484 0.972656C8.37891 0.824219 8.01562 0.75 7.625 0.75H5.375C4.98438 0.75 4.62109 0.824219 4.28516 0.972656C3.94141 1.12109 3.64062 1.32422 3.38281 1.58203C3.13281 1.83203 2.93359 2.12891 2.78516 2.47266C2.63672 2.80859 2.5625 3.17188 2.5625 3.5625V7.125H1.0625C0.90625 7.125 0.773438 7.17969 0.664062 7.28906C0.554688 7.39844 0.5 7.53125 0.5 7.6875V12.1875C0.5 12.3438 0.554688 12.4766 0.664062 12.5859C0.773438 12.6953 0.90625 12.75 1.0625 12.75H11.9375C12.0938 12.75 12.2266 12.6953 12.3359 12.5859C12.4453 12.4766 12.5 12.3438 12.5 12.1875V7.6875C12.5 7.53125 12.4453 7.39844 12.3359 7.28906C12.2266 7.17969 12.0938 7.125 11.9375 7.125ZM3.6875 3.5625C3.6875 3.09375 3.85156 2.69531 4.17969 2.36719C4.50781 2.03906 4.90625 1.875 5.375 1.875H7.625C8.09375 1.875 8.49219 2.03906 8.82031 2.36719C9.14844 2.69531 9.3125 3.09375 9.3125 3.5625V7.125H3.6875V3.5625ZM11.375 11.625H1.625V8.25H11.375V11.625ZM7.0625 9.9375C7.0625 10.0938 7.00781 10.2266 6.89844 10.3359C6.78906 10.4453 6.65625 10.5 6.5 10.5C6.34375 10.5 6.21094 10.4453 6.10156 10.3359C5.99219 10.2266 5.9375 10.0938 5.9375 9.9375C5.9375 9.78125 5.99219 9.64844 6.10156 9.53906C6.21094 9.42969 6.34375 9.375 6.5 9.375C6.65625 9.375 6.78906 9.42969 6.89844 9.53906C7.00781 9.64844 7.0625 9.78125 7.0625 9.9375Z"
										fill="#0057AD"
									/>
								</svg>
								<a
									href="/vacctrak"
									className="font-bold text-brand-500"
									onClick={() => ga('Log in')}
								>
									Log in
								</a>
							</div>
						</div>
					</header>
					<div className="relative z-30 flex flex-wrap max-w-screen-xl mx-auto overflow-visible lg:flex-row lg:justify-between">
						<div className="w-full px-6 mb-32 space-y-8 lg:mb-0 lg:pr-20 lg:space-y-6 lg:w-1/2 xl:w-6/12">
							<h1 className="text-3xl font-bold leading-tight md:text-4xl lg:text-5xl text-brand-500">
								FREE business vaccination tracker
							</h1>
							<div className="flex flex-col items-center space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6 lg:hidden">
								<Button
									as="a"
									href="#form"
									className="block w-full sm:w-auto sm:inline-block lg:hidden"
								>
									Get started
								</Button>
								<div className="flex items-center space-x-2 lg:hidden">
									<svg
										className="current-fill"
										width="13"
										height="13"
										viewBox="0 0 13 13"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.9375 7.125H10.4375V3.5625C10.4375 3.17188 10.3633 2.80859 10.2148 2.47266C10.0664 2.12891 9.86328 1.83203 9.60547 1.58203C9.35547 1.32422 9.05859 1.12109 8.71484 0.972656C8.37891 0.824219 8.01562 0.75 7.625 0.75H5.375C4.98438 0.75 4.62109 0.824219 4.28516 0.972656C3.94141 1.12109 3.64062 1.32422 3.38281 1.58203C3.13281 1.83203 2.93359 2.12891 2.78516 2.47266C2.63672 2.80859 2.5625 3.17188 2.5625 3.5625V7.125H1.0625C0.90625 7.125 0.773438 7.17969 0.664062 7.28906C0.554688 7.39844 0.5 7.53125 0.5 7.6875V12.1875C0.5 12.3438 0.554688 12.4766 0.664062 12.5859C0.773438 12.6953 0.90625 12.75 1.0625 12.75H11.9375C12.0938 12.75 12.2266 12.6953 12.3359 12.5859C12.4453 12.4766 12.5 12.3438 12.5 12.1875V7.6875C12.5 7.53125 12.4453 7.39844 12.3359 7.28906C12.2266 7.17969 12.0938 7.125 11.9375 7.125ZM3.6875 3.5625C3.6875 3.09375 3.85156 2.69531 4.17969 2.36719C4.50781 2.03906 4.90625 1.875 5.375 1.875H7.625C8.09375 1.875 8.49219 2.03906 8.82031 2.36719C9.14844 2.69531 9.3125 3.09375 9.3125 3.5625V7.125H3.6875V3.5625ZM11.375 11.625H1.625V8.25H11.375V11.625ZM7.0625 9.9375C7.0625 10.0938 7.00781 10.2266 6.89844 10.3359C6.78906 10.4453 6.65625 10.5 6.5 10.5C6.34375 10.5 6.21094 10.4453 6.10156 10.3359C5.99219 10.2266 5.9375 10.0938 5.9375 9.9375C5.9375 9.78125 5.99219 9.64844 6.10156 9.53906C6.21094 9.42969 6.34375 9.375 6.5 9.375C6.65625 9.375 6.78906 9.42969 6.89844 9.53906C7.00781 9.64844 7.0625 9.78125 7.0625 9.9375Z"
											fill="#0057AD"
										/>
									</svg>
									<a
										href="/vacctrak"
										className="font-bold text-brand-500"
										onClick={() => ga('Log in')}
									>
										Log in
									</a>
								</div>
							</div>
							<ul className="relative pt-2 mb-4 list-inside tick-list last:mb-0">
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									<span className="font-bold">Reopen safely</span> with{' '}
									<span className="font-bold underline">FREE</span>{' '}
									{isANZ ? 'initial' : ''} HR and health & safety advice.
								</li>
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									<span className="font-bold">
										Reassure customers and employees that your business is safe
									</span>{' '}
									with the VaccTrak pass.
								</li>
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									<span className="font-bold">Educate your staff</span> with our
									expert e-learning tools.
								</li>
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									<span className="font-bold">
										Be confident in your COVID processes
									</span>{' '}
									{isANZ
										? 'with our suite of essential resources.'
										: 'with our downloadable templates and policies.'}
								</li>
							</ul>
							<img className="inline-block w-64 mb-16" src={feefoLogo} alt="Feefo" />
						</div>
						<div className="relative w-full lg:-mb-20 lg:w-1/2 xl:w-5/12">
							<div className="pt-24 bg-brand-100 sm:py-24 lg:bg-transparent lg:py-0">
								<div id="form" className="anchor" />
								<div className="absolute top-0 right-0 z-10 -mt-16 -mr-16 transform rotate-3">
									<img src={vaccTrackApp} alt="app" />
								</div>
								<div className="absolute bottom-0 left-0 z-0 hidden ml-12 -mb-16 transform sm:block lg:-ml-40 -rotate-6">
									<img src={vaccTrackPasses} alt="passes" />
								</div>
								<Form />
							</div>
						</div>
					</div>
				</div>
				<div className="py-16">
					<div className="max-w-screen-xl px-8 mx-auto text-center">
						<p className="inline-block mb-12 text-gray-600">As seen on</p>
						<div className="flex flex-wrap items-center justify-center space-y-6 xl:flex-nowrap xl:space-y-0 xl:space-x-16">
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img className="w-32 mx-auto" src={asoskynews} alt="Sky News" />
							</div>
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img className="w-32 mx-auto" src={asolbc} alt="LBC" />
							</div>
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img className="w-32 mx-auto" src={asotimes} alt="Times Radio" />
							</div>
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img
									className="w-32 mx-auto"
									src={asobbcrn}
									alt="BBC Radio Nottingham"
								/>
							</div>
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img
									className="w-32 mx-auto"
									src={asobbcrs}
									alt="BBC Radio Solent"
								/>
							</div>
							<div className="w-1/2 md:w-1/3 xl:w-auto">
								<img className="w-32 mx-auto" src={asobbcrwm} alt="BBC Radio WM" />
							</div>
						</div>
					</div>
				</div>
				<div className="relative z-40 py-16">
					<div className="max-w-screen-xl px-8 mx-auto">
						<div className="mb-20 space-y-6 text-center">
							<LinkButton
								className="inline-block cursor-pointer"
								onClick={() => {
									setShowVideoModal(true);
									ga('Watch video now');
								}}
							>
								<img
									className="inline-block"
									src={vaccTrackScreens}
									alt="VaccTrak Lite"
								/>
								<p className="text-sm text-center text-gray-600 xl:text-base">
									Watch as Sam from BrightHR explains the benefits of VaccTrak
									Lite
								</p>
							</LinkButton>
						</div>
						<div className="mb-20 space-y-6 text-center">
							<h1 className="text-3xl font-bold lg:text-5xl">
								VaccTrak’s range of exclusive features
							</h1>
							<p>
								As a business owner, you have a responsibility to your employees and
								the general public to make sure your workplace is safe to reopen
								again. <br /> Here’s how VaccTrak Lite supports you...
							</p>
						</div>
						<div className="flex flex-wrap max-w-screen-xl mx-auto">
							<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
								<img src={users} alt="Log COVID-19 vaccinations with ease" />
								<h3 className="text-xl font-bold">
									Log COVID-19 vaccinations with ease
								</h3>
								<p>
									Keep a clear record of who’s had all doses of the COVID-19
									vaccine in your business.
								</p>
							</div>
							<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
								<img src={documentSvg} alt="Access clear progress reports" />
								<h3 className="text-xl font-bold">Access clear progress reports</h3>
								<p>
									Download regular reports to see exactly how many employees have
									been vaccinated in your workforce.
								</p>
							</div>
							<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
								<img src={passport} alt="Send VaccTrak passes to staff" />
								<h3 className="text-xl font-bold">
									Send VaccTrak passes to staff{' '}
								</h3>
								<p>
									You can issue your employees with a secure VaccTrak pass, and
									they can use it to prove they’re safe to return to the workplace
									or visit client sites.
								</p>
							</div>
							{!isANZ && (
								<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
									<img src={documents} alt="Complete resource library" />
									<h3 className="text-xl font-bold">Complete resource library</h3>
									<p>
										Access a range of professional vaccination resources
										including letter templates, vaccination policies, and online
										video guides.
									</p>
								</div>
							)}
							<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
								<img src={checks} alt="Enjoy expert e-learning" />
								<h3 className="text-xl font-bold">Enjoy expert e-learning</h3>
								<p>
									Access our exclusive vaccine awareness course to help you
									understand how the vaccine works and the benefits of having it.
									Plus, share the course with your staff in a click.
								</p>
							</div>
							<div className="px-5 mb-6 space-y-4 md:w-1/2 lg:w-1/3">
								<img src={phone} alt="Get FREE advice" />
								<h3 className="text-xl font-bold">
									Get FREE {isANZ ? 'initial' : ''} advice
								</h3>
								<p>
									Whether you need advice on how to approach staff about the
									vaccine or guidance on how to reopen your business safely, our
									{isANZ ? ' team' : ' experts'} are available{' '}
									<span className="font-bold">Monday to Friday, 9am to 5pm</span>,
									to answer all your HR and health & safety queries.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="relative z-40 py-16">
					<div className="max-w-screen-xl px-8 mx-auto">
						<div className="flex flex-wrap justify-between max-w-screen-xl mx-auto">
							<div className="px-4 mb-6 lg:w-1/2 lg:mb-0">
								<div className="p-12 space-y-6 bg-white border-t-8 rounded-lg shadow-lg border-brand-500 ">
									<div className="w-8 transform opacity-25 text-brand-500 -rotate-3">
										<img className="text-brand-500" src={Quote} alt="" />
									</div>
									<p>
										The VaccTrak feature has been really useful for us at EF. As
										a Health & Social Care organisation still working with
										clients and in the community, it’s been useful to be able to
										use the VaccTrak system to monitor which areas/teams are
										still at risk of catching COVID-19 and those who may be less
										at risk (where more people have taken up the vaccine).
									</p>
									<p className="font-bold">
										Victoria Duncan – Emerging Futures CIC
									</p>
								</div>
							</div>
							<div className="px-4 mb-6 lg:w-1/2 lg:mb-0">
								<div className="p-12 space-y-6 bg-white border-t-8 rounded-lg shadow-lg border-brand-500 ">
									<div className="w-8 transform opacity-25 text-brand-500 -rotate-3">
										<img className="text-brand-500" src={Quote} alt="" />
									</div>
									<p>
										BrightHR’s VaccTrak feature has been really helpful in
										recording the vaccination statuses of our staff, grouping
										them by how many vaccines they’ve each had to date, and
										monitoring those not yet vaccinated due to allergies. This
										is essential in the care industry, and has made the process
										of compiling and maintaining records much easier.
									</p>
									<p className="font-bold">Kelly McGillan – DK Home Support</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="py-12">
					<div className="flex flex-col items-center justify-center max-w-screen-xl px-8 mx-auto space-y-12 text-center text-gray-900 lg:flex-row lg:space-y-0">
						<img
							className="inline-block w-40 fill-current lg:mr-12"
							src={vaccTrackLogoDark}
							alt="VaccTrak Lite"
						/>
						<p className="order-last lg:order-none">&copy; 2021. VaccTrak</p>
						<img
							className="inline-block w-40 lg:ml-auto"
							src={brightLogo}
							alt="BrightHR"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default BrochureWare;
