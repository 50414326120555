import React from 'react';
import { Field } from 'formik';

export default (props) => {
	return (
		<Field name={props.name}>
			{({ field, meta }) => (
				<div>
					<input
						{...field}
						{...props}
						className={`border-2 border-gray-500 rounded px-4 py-2 my-2 max-w-full ${props.className}`}
					/>
					{meta.touched && meta.error && <div className="text-error">{meta.error}</div>}
				</div>
			)}
		</Field>
	);
};
