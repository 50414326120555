import getAllEmployees from 'services/employee/getAllEmployees';
import getAllEmployeesCovidVaccinationStatus from '../getAllEmployees';

const getAllEmployeesFullInformation = () =>
	Promise.all([getAllEmployees(), getAllEmployeesCovidVaccinationStatus()]).then(
		([
			allEmployees,
			{
				_embedded: { collection },
			},
		]) => {
			const findEmployee = (guid) =>
				collection.find((employee) => {
					return employee.employeeGuid === guid;
				}) || {
					vaccinationStatus: 'Not specified',
				};

			return allEmployees.reduce((curr, employee) => {
				return [...curr, { ...employee, ...findEmployee(employee.guid) }];
			}, []);
		}
	);

export default getAllEmployeesFullInformation;
