const getFileNameFromHeader = (contentDisposition) =>
	contentDisposition && contentDisposition.split('filename=')[1];

export default ({ headers = {}, data }) => {
	const filename = getFileNameFromHeader(headers['content-disposition']) || `report.csv`;

	const contentType = headers['content-type'] || 'application/octet-stream';

	const blob = new Blob([data], {
		type: contentType,
	});

	return {
		filename,
		blob,
	};
};
