import React, { useState } from 'react';
import { Button } from 'bright-components';
import styled from 'styled-components';
import generateReport from 'services/reports/generateReport';

const SadButton = styled(Button)`
	& svg {
		display: inline-block;
	}
`;

export default ({ children, onClick, ...props }) => {
	const [reportLoading, setReportLoading] = useState(false);
	const genReport = () => {
		setReportLoading(true);
		generateReport().then(() => setReportLoading(false));
	};

	return (
		<SadButton
			{...props}
			className="self-start whitespace-no-wrap"
			onClick={() => {
				onClick();
				genReport();
			}}
			loading={reportLoading}
		>
			{children}
		</SadButton>
	);
};
