import generateAsyncReport from 'services/reports/generateAsyncReport';
import saveFile from './saveFile';
import downloadFile from './downloadFile';

export default () => {
	const buildReportName = ({ blob }) => ({
		blob,
		filename: `vacctrak.csv`,
	});

	return generateAsyncReport({
		type: 'vaccTrack',
		params: { includeTerminated: true, format: 'csv' },
	})
		.then(downloadFile)
		.then(buildReportName)
		.then(saveFile);
};
