import React from 'react';
import ga from 'utils/ga/navigation';
import { useLocation, useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';

export default ({ pages }) => {
	const { pathname } = useLocation();
	const { push } = useHistory();

	return (
		<>
			<select
				onChange={(event) => push(event.target.value)}
				value={pathname}
				className="block sm:hidden w-full bg-white p-4 border-2 rounded border-gray-700"
			>
				{pages.map(({ pathname: path, title }) => (
					<option key={title} value={path}>
						{title}
					</option>
				))}
			</select>

			<ul className="sm:border-b border-white flex-row hidden sm:flex">
				{pages.map(({ pathname: path, title }) => (
					<li key={title}>
						<Link
							className={classnames({
								'block px-8 py-3 pb border-white text-white': true,
								'border-b-4 font-bold': pathname === path,
							})}
							to={path}
							onClick={() => ga(title)}
						>
							{title}
						</Link>
					</li>
				))}
			</ul>
		</>
	);
};
