import React, { useState } from 'react';
import styled from 'styled-components';
import ga from 'utils/ga/getStartedForm';
import { Formik } from 'formik';
import { Button, Modal, ModalBody } from 'bright-components';
import Cross from 'bright-components/dist/components/Icons/Cross';
import * as Yup from 'yup';
import register from 'services/registration';
import emailAvailable from 'services/emailAddressAvailable';
import config from 'utils/config';
import translate from 'utils/translate';
import { currentLocale } from 'utils/locale';
import thanks from '../images/thanks.png';
import freeSticker from '../images/FreeSticker.svg';

const schema = Yup.object().shape({
	firstName: Yup.string().label('First name').max(50).required(),
	lastName: Yup.string().label('Last name').max(50).required(),
	email: Yup.string().email().label('Email address').max(250).required(),
	companyName: Yup.string().label('Company name').max(50).required(),
	phoneNumber: Yup.number()
		.integer('Phone number must be a valid number')
		.positive('Phone number must be a valid number')
		.label('Phone number'),
	tandc: Yup.bool()
		.required('The terms and conditions must be accepted')
		.oneOf([true], 'The terms and conditions must be accepted'),
});

const SadButton = styled(Button)`
	& svg {
		display: inline-block;
	}
`;

export default () => {
	const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
	const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState(false);

	const { BRIGHT_HR_LINK } = config();
	const { TERMS_LINK } = translate();

	return (
		<>
			{emailAlreadyRegistered && (
				<Modal width="medium" version="2">
					<ModalBody className="text-center">
						<Cross
							size={20}
							className="absolute right-0 top-0 mr-3 mt-3 cursor-pointer"
							onClick={() => setEmailAlreadyRegistered(false)}
						/>
						<img src={thanks} alt="" className="mx-auto mb-4" />
						<h2 className="font-bold text-2xl mb-3">
							It looks like you already have an account
						</h2>
						<p className="mb-4">
							Please log into your BrightHR to access all your exclusive VaccTrak
							features and much more.
						</p>
						<Button
							as="a"
							href={BRIGHT_HR_LINK}
							className="mb-4"
							onClick={() => ga('Log in now')}
						>
							Log into BrightHR
						</Button>
						<p className="mb-4">
							Alternatively, you can close this tab and try a different email address.
						</p>
						<p className="mb-4">
							Need a bit of help? Please call our support team now on{' '}
							<a className="font-bold" href="tel:0800 470 2432">
								0800 470 2432
							</a>
						</p>
					</ModalBody>
				</Modal>
			)}
			{submissionSuccessful && !emailAlreadyRegistered && (
				<Modal width="medium" version="2">
					<ModalBody>
						<Cross
							size={20}
							className="absolute right-0 top-0 mr-3 mt-3 cursor-pointer"
							onClick={() => setSubmissionSuccessful(false)}
						/>
						<img src={thanks} alt="" className="mx-auto mb-4" />
						<h2
							className="font-bold text-2xl text-center mb-3"
							data-e2e="signup-complete"
						>
							Thanks for registering
						</h2>
						<p className="text-center mb-4">
							An email is on its way to you confirming your VaccTrak Lite account and
							giving you instructions on what to do next—so head to your inbox for
							more details.
						</p>
					</ModalBody>
				</Modal>
			)}
			{!submissionSuccessful && !emailAlreadyRegistered && (
				<>
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							email: '',
							companyName: '',
							phoneNumber: '',
							tandc: false,
						}}
						validationSchema={schema}
						onSubmit={(data, { setSubmitting }) => {
							ga('Get started');
							if (window.dataLayer) {
								window.dataLayer.push({
									event: 'Form Submission',
									formID: 'homepageForm',
								});
							}
							return emailAvailable(data.email).then((available) => {
								if (!available) {
									setEmailAlreadyRegistered(true);
									return setSubmitting(false);
								}

								const utm = JSON.parse(localStorage.getItem('utm'));
								const countryName = currentLocale.name;
								const origin = 'VaccTrak Lite';

								return register({ ...data, utm, countryName, origin }).then(() => {
									setSubmissionSuccessful(true);
									setSubmitting(false);
								});
							});
						}}
					>
						{(formik) => (
							<form
								className="relative z-30 sm:max-w-xl lg:max-w-none p-8 mx-auto sm:rounded-lg sm:shadow-md bg-white"
								onSubmit={formik.handleSubmit}
							>
								<div className="absolute z-40 top-0 left-0 -mt-32 sm:-ml-16">
									<img src={freeSticker} alt="FREE" />
								</div>
								<div className="sm:flex">
									<div className="mb-8 mr-2 sm:w-1/2">
										<label htmlFor="firstName">
											<div className="font-bold mb-3">First name</div>
											<input
												id="firstName"
												value={formik.values.firstName}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => ga('First name')}
												name="firstName"
												className="m-0 border-2 border-gray-300 rounded-sm w-full px-3 py-1"
											/>
										</label>
										{formik.touched.firstName && (
											<div className="text-error">
												{formik.errors.firstName}
											</div>
										)}
									</div>
									<div className="mb-8 sm:w-1/2">
										<label htmlFor="lastName">
											<div className="font-bold mb-3">Last name</div>
											<input
												id="lastName"
												value={formik.values.lastName}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => ga('Last name')}
												name="lastName"
												className="m-0 border-2 border-gray-300 rounded-sm w-full px-3 py-1"
											/>
										</label>
										{formik.touched.lastName && (
											<div className="text-error">
												{formik.errors.lastName}
											</div>
										)}
									</div>
								</div>
								<div className="mb-8">
									<label htmlFor="email">
										<div className="font-bold mb-3">Email address</div>
										<input
											id="email"
											value={formik.values.email}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onFocus={() => ga('Email address')}
											name="email"
											className="m-0 border-2 border-gray-300 rounded-sm w-full px-3 py-1"
										/>
									</label>
									{formik.touched.email && (
										<div className="text-error">{formik.errors.email}</div>
									)}
								</div>
								<div className="mb-8">
									<label htmlFor="companyName">
										<div className="font-bold mb-3">Company name</div>
										<input
											id="companyName"
											value={formik.values.companyName}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onFocus={() => ga('Company name')}
											name="companyName"
											className="m-0 border-2 border-gray-300 rounded-sm w-full px-3 py-1"
										/>
									</label>
									{formik.touched.companyName && (
										<div className="text-error">
											{formik.errors.companyName}
										</div>
									)}
								</div>
								<div className="mb-8">
									<label htmlFor="phoneNumber">
										<div className="font-bold mb-3">
											Phone number{' '}
											<span className="text-gray-500 font-normal">
												(optional)
											</span>
										</div>
										<input
											id="phoneNumber"
											type="number"
											value={formik.values.phoneNumber}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onFocus={() => ga('Phone number')}
											name="phoneNumber"
											className="m-0 border-2 border-gray-300 rounded-sm w-full px-3 py-1"
										/>
									</label>
									{formik.touched.phoneNumber && (
										<div className="text-error">
											{formik.errors.phoneNumber}
										</div>
									)}
								</div>
								<div className="mb-8">
									<div className="flex">
										<input
											name="tandc"
											onChange={formik.handleChange}
											type="checkbox"
											className="h-5 w-5 mr-3"
											onClick={() => ga('Privacy and GDPR statement')}
										/>{' '}
										<div>
											I have read and agree to the{' '}
											<a
												className="underline text-brand-500"
												href={TERMS_LINK}
												target="_blank"
												rel="noreferrer noopener"
											>
												terms and conditions
											</a>
										</div>
									</div>
									{formik.touched.tandc && (
										<div className="text-error">{formik.errors.tandc}</div>
									)}
								</div>

								<div className="text-center" data-e2e="submit-signup">
									<SadButton
										className=""
										type="submit"
										loading={formik.isSubmitting}
										disabled={formik.isSubmitting}
									>
										Get started
									</SadButton>
								</div>
							</form>
						)}
					</Formik>
				</>
			)}
		</>
	);
};
