import http from 'utils/http';
import config from 'utils/config';

const editEmployee = ({ guid, firstName, lastName, email }) => {
	const { GLOBAL_API } = config();
	const path = `${GLOBAL_API}employee/${guid}`;

	const payload = {
		firstName,
		lastName,
		email,
	};

	return http.put(path, { data: payload });
};

export default editEmployee;
