import http from 'utils/http';
import config from 'utils/config';

const getMedicalInformation = () => {
	const { API_URL } = config();
	const path = `${API_URL}employee/covidvaccination`;
	return http.get(path).then(({ data }) => data);
};

export default getMedicalInformation;
