import React, { useState } from 'react';
import {
	FormikSelect,
	Option,
	DayPicker,
	FormikTextarea,
	LinkButton,
	Switch,
	Colors,
	Modal,
	ModalHeader,
	ModalBody,
} from 'bright-components';
import Information from 'bright-components/dist/components/Icons/Information';

const EvidenceCheckToggleArea = ({ setShowModal }) => {
	return (
		<div className="mb-2">
			Evidence of vaccination provided?
			<Information
				onClick={() => setShowModal(true)}
				className="inline-block ml-2 cursor-pointer"
				size={20}
				color={Colors.link}
			/>
		</div>
	);
};

export const VaccineStatus = ({ formik: { values, setFieldValue }, index = 0, ga = () => {} }) => {
	const yesOrPending =
		values[`vaccinationStatus${index}`] === 'Yes' ||
		values[`vaccinationStatus${index}`] === 'Pending';
	const statusOptions = ['Not Specified', 'Yes', 'Pending', 'No', 'Exempt', 'Prefer not to say'];
	return (
		<div className="flex items-center justify-end">
			<div className="mr-4">COVID-19 vaccinated</div>
			<FormikSelect
				name={`vaccinationStatus${index}`}
				onBlur={() => ga('status', values[`vaccinationStatus${index}`])}
			>
				{statusOptions.map((option) => (
					<Option value={option} name={option} key={option}>
						{option}
					</Option>
				))}
				;
			</FormikSelect>
			{!yesOrPending && (
				<LinkButton
					className="ml-4 outline-none"
					type="button"
					onClick={() => {
						ga(values[`showNotes${index}`] ? 'Clear notes' : 'Add notes');
						setFieldValue(`showNotes${index}`, !values[`showNotes${index}`]);
						setFieldValue(`notes${index}`, '');
					}}
				>
					{values[`showNotes${index}`] ? 'Clear notes' : 'Add notes'}
				</LinkButton>
			)}
		</div>
	);
};

export const VaccineInfo = ({ formik: { values, setFieldValue }, index = 0, ga = () => {} }) => {
	const [showModal, setShowModal] = useState(false);
	const yesOrPending =
		values[`vaccinationStatus${index}`] === 'Yes' ||
		values[`vaccinationStatus${index}`] === 'Pending';
	const vaccinationStyling = 'flex flex-col py-4 w-full lg:flex-row';
	const datePickerStyle = 'w-full pr-6 md:w-3/4 lg:w-1/3';
	const proofToggleStyle = 'flex items-center w-full lg:justify-center lg:w-1/3';
	const vaccinationHeaderStyle = 'flex font-semibold lg:font-normal items-center w-full lg:w-1/3';
	return (
		<>
			{showModal && (
				<Modal width="small" version="2" bgDismiss close={() => setShowModal(false)}>
					<ModalHeader>Evidence of vaccination provided?</ModalHeader>
					<ModalBody>
						You might accept a vaccination card, letter, email or text as evidence of
						your employee&apos;s vaccination.
					</ModalBody>
				</Modal>
			)}
			{yesOrPending && (
				<div className="flex flex-col lg:py-8">
					<div className="hidden w-full font-semibold lg:flex">
						<div className="w-1/3">Vaccine type</div>
						<div className="w-1/3">Date of vaccination</div>
						<div className="w-1/3">
							<EvidenceCheckToggleArea setShowModal={setShowModal} />
						</div>
					</div>
					<div className={vaccinationStyling}>
						<div className={vaccinationHeaderStyle}>First COVID-19 vaccine</div>
						<div className="flex lg:hidden ">Date vaccination received</div>
						<div className={datePickerStyle}>
							<DayPicker
								data-e2e="date-input"
								range={{
									from: values[`firstVaccinationDate${index}`]
										? new Date(values[`firstVaccinationDate${index}`])
										: undefined,
									to: undefined,
								}}
								yearRange={{ min: 2000, max: 2030 }}
								GA={{}}
								onSelectedDate={(e) => {
									ga('Date of first vaccine');
									setFieldValue(`firstVaccinationDate${index}`, e.from);
									if (!e.from)
										setFieldValue(`firstVaccinationSeenProof${index}`, false);
								}}
								name={`firstVaccinationDate${index}`}
							/>
						</div>

						{values[`firstVaccinationDate${index}`] &&
							values[`vaccinationStatus${index}`] !== 'Pending' && (
								<>
									<div className="flex pt-4 lg:p-0 lg:hidden">
										<EvidenceCheckToggleArea setShowModal={setShowModal} />
									</div>
									<div className={proofToggleStyle}>
										<Switch
											id={`firstVaccinationSeenProof${values.guid || index}`}
											name={`firstVaccinationSeenProof${index}`}
											checked={
												values[`firstVaccinationSeenProof${index}`] || false
											}
											onChange={(e) => {
												ga(
													'Any proof of first vaccination?',
													e.currentTarget.checked ? 'on' : 'off'
												);
												setFieldValue(
													`firstVaccinationSeenProof${index}`,
													e.currentTarget.checked
												);
											}}
										/>
									</div>
								</>
							)}
					</div>
					{values[`firstVaccinationDate${index}`] && (
						<div className={vaccinationStyling}>
							<div className={vaccinationHeaderStyle}>Second COVID-19 vaccine</div>
							<div className="flex lg:hidden">Date vaccination received</div>
							<div className={datePickerStyle}>
								<DayPicker
									data-e2e="date-input"
									range={{
										from: values[`vaccinationDate${index}`]
											? new Date(values[`vaccinationDate${index}`])
											: undefined,
										to: undefined,
									}}
									yearRange={{ min: 2000, max: 2030 }}
									GA={{}}
									onSelectedDate={(e) => {
										ga('Date of final vaccine');
										setFieldValue(`vaccinationDate${index}`, e.from);
										if (!e.from)
											setFieldValue(
												`finalVaccinationSeenProof${index}`,
												false
											);
									}}
									name={`vaccinationDate${index}`}
								/>
							</div>
							{values[`vaccinationDate${index}`] &&
								values[`vaccinationStatus${index}`] !== 'Pending' && (
									<>
										<div className="flex pt-4 lg:p-0 lg:hidden">
											<EvidenceCheckToggleArea setShowModal={setShowModal} />
										</div>
										<div className={proofToggleStyle}>
											<Switch
												id={`finalVaccinationSeenProof${
													values.guid || index
												}`}
												name={`finalVaccinationSeenProof${index}`}
												checked={
													values[`finalVaccinationSeenProof${index}`] ||
													false
												}
												onChange={(e) => {
													ga(
														'Any proof of final vaccination?',
														e.currentTarget.checked ? 'on' : 'off'
													);
													setFieldValue(
														`finalVaccinationSeenProof${index}`,
														e.currentTarget.checked
													);
												}}
											/>
										</div>
									</>
								)}
						</div>
					)}
					{values[`vaccinationDate${index}`] && values[`firstVaccinationDate${index}`] && (
						<div className={vaccinationStyling}>
							<div className={vaccinationHeaderStyle}>
								COVID-19 booster vaccine one
							</div>

							<div className="flex lg:hidden">Date vaccination received</div>
							<div className={datePickerStyle}>
								<DayPicker
									range={{
										from: values[`boosterVaccinationDate${index}`]
											? new Date(values[`boosterVaccinationDate${index}`])
											: undefined,
										to: undefined,
									}}
									yearRange={{ min: 2000, max: 2030 }}
									GA={{}}
									onSelectedDate={(e) => {
										ga('Date of booster vaccine');
										setFieldValue(`boosterVaccinationDate${index}`, e.from);
										if (!e.from)
											setFieldValue(
												`finalVaccinationSeenProof${index}`,
												false
											);
									}}
									name={`boosterVaccinationDate${index}`}
								/>
							</div>
							{values[`boosterVaccinationDate${index}`] &&
								values[`vaccinationStatus${index}`] !== 'Pending' && (
									<>
										<div className="flex pt-4 lg:p-0 lg:hidden">
											<EvidenceCheckToggleArea setShowModal={setShowModal} />
										</div>
										<div className={proofToggleStyle}>
											<Switch
												id={`boosterVaccinationSeenProof${
													values.guid || index
												}`}
												name={`boosterVaccinationSeenProof${index}`}
												checked={
													values[`boosterVaccinationSeenProof${index}`] ||
													false
												}
												onChange={(e) => {
													ga(
														'Any proof of booster vaccination?',
														e.currentTarget.checked ? 'on' : 'off'
													);
													setFieldValue(
														`boosterVaccinationSeenProof${index}`,
														e.currentTarget.checked
													);
												}}
											/>
										</div>
									</>
								)}
						</div>
					)}

					{values[`vaccinationDate${index}`] && values[`firstVaccinationDate${index}`] && (
						<div className={vaccinationStyling}>
							<div className={vaccinationHeaderStyle}>
								COVID-19 booster vaccine two
							</div>

							<div className="flex lg:hidden">Date vaccination received</div>
							<div className={datePickerStyle}>
								<DayPicker
									range={{
										from: values[`secondBoosterDate${index}`]
											? new Date(values[`secondBoosterDate${index}`])
											: undefined,
										to: undefined,
									}}
									yearRange={{ min: 2000, max: 2030 }}
									GA={{}}
									onSelectedDate={(e) => {
										ga('Date of booster vaccine');
										setFieldValue(`secondBoosterDate${index}`, e.from);
										if (!e.from)
											setFieldValue(
												`finalVaccinationSeenProof${index}`,
												false
											);
									}}
									name={`secondBoosterDate${index}`}
								/>
							</div>
							{values[`secondBoosterDate${index}`] &&
								values[`vaccinationStatus${index}`] !== 'Pending' && (
									<>
										<div className="flex pt-4 lg:p-0 lg:hidden">
											<EvidenceCheckToggleArea setShowModal={setShowModal} />
										</div>
										<div className={proofToggleStyle}>
											<Switch
												id={`secondBoosterVaccinationSeenProof${
													values.guid || index
												}`}
												name={`secondBoosterVaccinationSeenProof${index}`}
												checked={
													values[
														`secondBoosterVaccinationSeenProof${index}`
													] || false
												}
												onChange={(e) => {
													ga(
														'Any proof of booster vaccination?',
														e.currentTarget.checked ? 'on' : 'off'
													);
													setFieldValue(
														`secondBoosterVaccinationSeenProof${index}`,
														e.currentTarget.checked
													);
												}}
											/>
										</div>
									</>
								)}
						</div>
					)}
				</div>
			)}

			{(values[`showNotes${index}`] || yesOrPending) && (
				<div style={{ maxWidth: '241px' }}>
					<div>Notes</div>
					<FormikTextarea fullWidth name={`notes${index}`} onFocus={() => ga('Notes')} />
				</div>
			)}
		</>
	);
};
