import axios from 'axios';
import config from 'utils/config';

const emailAddressAvailable = (email) => {
	const { GLOBAL_API } = config();
	const uri = `${GLOBAL_API}account/availability?username=${email}`;

	return axios.get(uri).then(({ data: { available } }) => available);
};

export default emailAddressAvailable;
