import React, { useState } from 'react';
import ga from 'utils/ga/myEmployees';
import { Pill, Colors, Button, Modal, ModalBody, ButtonGroup } from 'bright-components';
import Pencil from 'bright-components/dist/components/Icons/Pencil';
import Trash from 'bright-components/dist/components/Icons/Trash';
import { Formik } from 'formik';
import * as Yup from 'yup';
import getToken from 'utils/getToken';
import ExpandoBar from '../../components/ExpandoBar';
import FormikInput from '../../../components/FormikInput';

const validationSchema = Yup.object().shape({
	firstName: Yup.string().label('First name').max(50).required(),
	lastName: Yup.string().label('Last name').max(50).required(),
	email: Yup.string().email().required(),
});

const AlreadyAdded = ({ allEmployees, employeeInfoUpdated, employeeDeleted }) => {
	const [expanded, setExpanded] = useState(false);
	const [editing, setEditing] = useState(null);
	const [deleting, setDeleting] = useState(null);

	return (
		<ExpandoBar
			expanded={expanded}
			onToggle={() => {
				setExpanded(!expanded);
				setEditing(null);
			}}
			title="My employees"
			icon={<Pill>{allEmployees.length}</Pill>}
		>
			{deleting && (
				<Modal width="350px" version="2" close={() => setDeleting(null)}>
					<ModalBody className="text-center">
						<p className="mx-4 my-4 bg-white">
							Do you want to permanently delete this employee?
						</p>
						<div className="flex justify-around">
							<Button
								outline
								onClick={() => {
									ga('Delete cancel');
									setDeleting(null);
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									ga('Delete confirm');
									employeeDeleted(deleting);
									setDeleting(null);
								}}
							>
								Delete
							</Button>
						</div>
					</ModalBody>
				</Modal>
			)}
			<Formik
				initialValues={{}}
				validationSchema={validationSchema}
				onSubmit={(values) => {
					setEditing(null);
					employeeInfoUpdated(values);
				}}
			>
				{(formik) => (
					<form onSubmit={formik.handleSubmit}>
						{allEmployees.map((employee) => {
							const edit = editing === employee.guid;
							const isSelf = getToken().sub === employee.guid;
							return (
								<div
									key={employee.guid}
									className="even:bg-brand-100 flex p-4 flex-col md:flex-row break-all"
								>
									{edit && (
										<>
											<div className="flex md:flex-1 flex-col lg:flex-row">
												<div className="md:flex-1">
													<FormikInput
														name="firstName"
														placeholder="First name"
													/>
												</div>
												<div className="md:flex-1">
													<FormikInput
														name="lastName"
														placeholder="Last name"
													/>
												</div>
											</div>
											<div className="flex md:flex-1 justify-between flex-col lg:flex-row">
												<FormikInput
													name="email"
													placeholder="Email address"
													disabled={isSelf}
												/>
												<ButtonGroup className="mt-3">
													<Button
														onClick={() => ga('Edit update')}
														disabled={
															formik.isSubmitting ||
															!formik.dirty ||
															!formik.isValid
														}
													>
														Update
													</Button>
													<Button
														type="button"
														onClick={() => {
															setEditing(null);
															ga('Edit cancel');
														}}
													>
														Cancel
													</Button>
												</ButtonGroup>
											</div>
										</>
									)}
									{!edit && (
										<>
											<div className="md:w-1/2">
												{employee.firstName} {employee.lastName}
											</div>
											<div className="flex md:w-1/2 justify-between flex-col sm:flex-row">
												<div>{employee.email}</div>
												<div className="flex justify-between w-16 mt-4 sm:mt-0">
													<Pencil
														className="cursor-pointer"
														onClick={() => {
															ga('Edit');
															setEditing(employee.guid);
															formik.resetForm({
																values: {
																	guid: employee.guid,
																	firstName: employee.firstName,
																	lastName: employee.lastName,
																	email: employee.email,
																},
															});
														}}
														size={20}
														color={Colors.link}
													/>
													{!isSelf && (
														<Trash
															className="cursor-pointer"
															size={20}
															color={Colors.link}
															onClick={() => {
																ga('Delete');
																setDeleting(employee.guid);
															}}
														/>
													)}
												</div>
											</div>
										</>
									)}
								</div>
							);
						})}
					</form>
				)}
			</Formik>
		</ExpandoBar>
	);
};

export default AlreadyAdded;
