import React, { useState } from 'react';
import { Button, Donut, LinkButton, InlineLoader } from 'bright-components';
import { Link } from 'react-router-dom';
import ga from 'utils/ga/hub';
import VideoModal from 'pages/components/videoModal';

import Tick from 'bright-components/dist/components/Icons/Tick';
import { isANZ } from 'utils/translateHelpers';
import config from 'utils/config';
import CourseIcon from './assets/covid-shield.svg';
import ReportIcon from './assets/reports.svg';
import Feefo from './assets/feefo-award.svg';
import SupportsImage from './assets/vacctrak-supports.png';
import ExpertAdviceImage from './assets/expert-advice.png';

import SideBar from '../components/SideBar';
import GenerateReport from '../components/GenerateReport';
import ZeroState from '../components/ZeroState';

const MyVaccTrak = ({
	employeesFullyVaccinated,
	employeesPartiallyVaccinated,
	employeesBoosted,
	employeesDoubleBoosted,
	allEmployees,
	loading,
}) => {
	const [showVideoModal, setShowVideoModal] = useState(false);
	const totalEmployees = allEmployees.length;
	const percentPartiallyVaccinated = Math.round(
		(employeesPartiallyVaccinated.length / totalEmployees) * 100
	);
	const percentFullyVaccinated = Math.round(
		(employeesFullyVaccinated.length / totalEmployees) * 100
	);
	const percentBoosted = Math.round((employeesBoosted.length / totalEmployees) * 100);
	const percentDoubleBoosted = Math.round((employeesDoubleBoosted.length / totalEmployees) * 100);

	const employeesHaveBeenAdded = totalEmployees > 1 || loading;
	const showSetStatusMessage =
		employeesHaveBeenAdded &&
		!employeesFullyVaccinated.length &&
		!employeesPartiallyVaccinated.length &&
		!loading;

	const { E_LEARNING_COURSE, SUPPORT_PHONE } = config();

	const keyCircleStyle = 'mt-4 sm:mt-0 mr-4 w-20 h-20 max-w-20 max-h-20';
	const donutTextStyle = 'absolute top-0 w-full h-full mt-3 text-3xl text-center';

	return (
		<div>
			{showVideoModal && (
				<VideoModal
					videoLink="https://player.vimeo.com/video/516691195"
					closeModal={() => setShowVideoModal(false)}
				/>
			)}

			<div className="w-full mb-8 text-white lg:w-1/2">
				<h1 className="mt-8 mb-5 text-2xl font-bold md:w-4/5" data-e2e="hub-heading">
					Protect your employees and customers with FREE VaccTrak{' '}
					<span className="font-normal">Lite</span>
				</h1>
				<ul className="grid grid-cols-1 gap-4 mb-8 md:grid-cols-2">
					<li className="flex items-center">
						<Tick size={20} className="inline-block mr-3" />
						<span>
							<b>Log vaccinations and track your business progress</b>.
						</span>
					</li>
					<li className="flex items-center">
						<Tick size={20} className="inline-block mr-3" />
						<span>
							<b>Reopen safely</b> with <b>FREE</b> {isANZ() ? 'initial' : ''} HR and
							health & safety advice.
						</span>
					</li>
					<li className="flex items-center">
						<Tick size={20} className="inline-block mr-3" />
						<span>
							<b>Reassure staff and customers that your business is safe</b> with the
							VaccTrak pass.
						</span>
					</li>
					<li className="flex items-center">
						<Tick size={20} className="inline-block mr-3" />
						<span>
							<b>Educate your staff</b> with our expert e-learning tools.
						</span>
					</li>
					{!isANZ() && (
						<li className="flex items-center">
							<Tick size={20} className="inline-block mr-3" />
							<span>
								<b>Be confident in your COVID-secure processes</b> with our
								downloadable documents.
							</span>
						</li>
					)}
					<li className="flex items-center">
						<Tick size={20} className="inline-block mr-3" />
						<span>
							<b>Keep confidential details safe</b> using our secure online tool.
						</span>
					</li>
				</ul>
				<img src={Feefo} alt="2021 feefo - platinum trusted service award" />
			</div>
			<div className="mt-4 md:flex">
				<div
					className="rounded-md md:w-2/3 lg:w-3/4"
					style={{ background: 'rgba(255,255,255,0.9)' }}
				>
					<div className="p-5">
						<div className="w-5/6 mb-6 font-bold">
							<div
								className="pb-4 text-4xl font-bold leading-10"
								data-e2e="percent-vaccinated"
							>
								Workforce vaccinated as of today
							</div>
							<div className="flex flex-col justify-between sm:flex-row">
								<div className="mb-6 space-y-6">
									<div className="text-xl">Vaccinations</div>
									<div className="flex items-center" data-e2e="full-count">
										<Donut
											className={keyCircleStyle}
											percents={[
												{
													percent: percentPartiallyVaccinated,
													color: '#FFC002',
												},
											]}
										>
											<div className="absolute top-0 flex items-center justify-center w-full h-full text-center">
												{loading ? (
													<InlineLoader
														isLoading
														size="50px"
														className="text-gray-300 fill-current"
													/>
												) : (
													<div className={donutTextStyle}>
														{employeesPartiallyVaccinated.length}
													</div>
												)}
											</div>
										</Donut>

										<div>
											<div className="text-xl">
												{loading
													? '-'
													: employeesPartiallyVaccinated.length}{' '}
												of {totalEmployees}
											</div>
											<div>First Vaccination</div>
										</div>
									</div>
									<div className="flex items-center" data-e2e="full-count">
										<Donut
											className={keyCircleStyle}
											percents={[
												{
													percent: percentFullyVaccinated,
													color: '#238C22',
												},
											]}
										>
											<div className="absolute top-0 flex items-center justify-center w-full h-full text-center">
												{loading ? (
													<InlineLoader
														isLoading
														size="50px"
														className="text-gray-300 fill-current"
													/>
												) : (
													<div className={donutTextStyle}>
														{employeesFullyVaccinated.length}
													</div>
												)}
											</div>
										</Donut>
										<div>
											<div className="text-xl">
												{loading ? '-' : employeesFullyVaccinated.length} of{' '}
												{totalEmployees}
											</div>
											<div>Second Vaccination</div>
										</div>
									</div>
								</div>
								<div className="space-y-6">
									<div className="text-xl">Boosters</div>
									<div className="flex items-center" data-e2e="full-count">
										<Donut
											className={keyCircleStyle}
											percents={[
												{
													percent: percentBoosted,
													color: '#f978bc',
												},
											]}
										>
											<div className="absolute top-0 flex items-center justify-center w-full h-full text-center">
												{loading ? (
													<InlineLoader
														isLoading
														size="50px"
														className="text-gray-300 fill-current"
													/>
												) : (
													<div className={donutTextStyle}>
														{employeesBoosted.length}
													</div>
												)}
											</div>
										</Donut>

										<div>
											<div className="text-xl">
												{loading ? '-' : employeesBoosted.length} of{' '}
												{totalEmployees}
											</div>
											<div>First Booster</div>
										</div>
									</div>
									<div className="flex items-center" data-e2e="full-count">
										<Donut
											className={keyCircleStyle}
											percents={[
												{
													percent: percentDoubleBoosted,
													color: '#4B0082',
												},
											]}
										>
											<div className="absolute top-0 flex items-center justify-center w-full h-full text-center">
												{loading ? (
													<InlineLoader
														isLoading
														size="50px"
														className="text-gray-300 fill-current"
													/>
												) : (
													<div className={donutTextStyle}>
														{employeesDoubleBoosted.length}
													</div>
												)}
											</div>
										</Donut>

										<div>
											<div className="text-xl">
												{loading ? '-' : employeesDoubleBoosted.length} of{' '}
												{totalEmployees}
											</div>
											<div>Second Booster</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div>
							{employeesHaveBeenAdded && !showSetStatusMessage && (
								<>
									<Button
										to="/vacctrak/employee-vaccination-status?vaccinated"
										className="w-full mr-3 shadow-md lg:w-auto"
										as={Link}
										onClick={() => ga('See vaccinated employees')}
									>
										See vaccinated employees
									</Button>
									<Button
										to="/vacctrak/employee-vaccination-status?not-vaccinated"
										as={Link}
										className="w-full mt-2 shadow-md lg:w-auto lg:mt-0"
										outline
										onClick={() => ga('Update Vaccination Statuses')}
									>
										Update vaccination statuses
									</Button>
								</>
							)}

							{!employeesHaveBeenAdded && <ZeroState />}
							{showSetStatusMessage && (
								<div>
									<h2 className="mb-3 text-lg font-bold">
										Update the vaccination status of employees
									</h2>
									<p className="mb-4">
										see exactly how many employees have been vaccinated and the
										date of their final doses
									</p>
									<Button
										to="/vacctrak/employee-vaccination-status?not-vaccinated"
										as={Link}
										className="w-full mt-2 shadow-md lg:w-auto lg:mt-0"
										onClick={() => ga('Update Vaccination Statuses')}
									>
										Update vaccine statuses
									</Button>
								</div>
							)}
						</div>
					</div>
					<div className="px-6 py-5 rounded rounded-t-none">
						<div className="flex p-6 mb-8 bg-white rounded shadow-lg">
							<img
								src={ReportIcon}
								className="hidden w-40 h-40 mr-5 fill-current text-brand-400 sm:block"
								alt=""
							/>
							<div>
								<span className="mb-5 text-lg font-bold text-black">
									Download employee vaccination reports
								</span>
								<p className="mb-4">
									At the tap of a button, you can access a clear report that shows
									you the progress of vaccination in your business. Use it to see
									exactly how many employees have been vaccinated and the date of
									their final doses.
								</p>
								{employeesHaveBeenAdded && (
									<GenerateReport outline onClick={() => ga('Download report')}>
										Download report
									</GenerateReport>
								)}
							</div>
						</div>
						<div className="flex p-6 bg-white rounded shadow-lg">
							<div>
								<span className="mb-5 text-lg font-bold text-black">
									Complete COVID-19 vaccine awareness course
								</span>
								<p className="mb-4">
									Take our online e-learning course to understand how the
									coronavirus vaccine works and get quick answers to all your
									vaccine questions. Plus, you can share the course with your
									staff in a click.
								</p>
								<Button
									outline
									as="a"
									rel="noopener noreferrer"
									target="_blank"
									href={E_LEARNING_COURSE}
									onClick={() => ga('Start course')}
								>
									Start course
								</Button>
							</div>
							<img
								src={CourseIcon}
								className="hidden w-40 h-40 ml-5 fill-current text-brand-400 sm:block"
								alt=""
							/>
						</div>
					</div>
				</div>
				<SideBar />
			</div>

			<div className="items-center pt-6 mb-8 md:flex">
				<div className="md:w-3/5 md:pr-24">
					<h3 className="pb-3 text-lg font-bold">How VaccTrak Lite supports you</h3>
					<p className="pb-2">
						As a business owner, you have a responsibility to your employees and the
						general public to make sure your workplace is safe to reopen again. And
						that’s why it’s crucial that you start tracking coronavirus vaccinations in
						your business.
					</p>
					<p className="pb-2">But don’t worry, VaccTrak Lite is here to help.</p>
					<p className="pb-2">
						It’s a free, no-obligation tool that lets you record who’s had the COVID-19
						vaccine in your business and who’s still on the waiting list, so you can get
						your people back to work as safely as possible.
					</p>
				</div>
				<LinkButton
					className="outline-none"
					onClick={() => {
						setShowVideoModal(true);
						ga('How VaccTrak Lite supports you Play video');
					}}
				>
					<img src={SupportsImage} alt="woman standing in the BrightHR office" />
				</LinkButton>
			</div>

			<div className="flex-row-reverse items-center pt-6 mb-8 md:flex items-reverse">
				<div className="md:w-3/5 md:pl-24">
					<h3 className="pb-3 text-lg font-bold">
						Get {isANZ() ? ' FREE initial ' : ' expert '} advice
					</h3>
					<p className="pb-2">
						Whether you need advice on how to approach staff about the vaccine or
						guidance on how to reopen your business safely, our{' '}
						{isANZ() ? 'team' : 'experts'} are available Monday to Friday, 9am to 5pm,
						to answer all your HR and health & safety queries.
					</p>
					<p className="pb-2">
						Plus, our advisors won’t bombard you with legal jargon either. Their advice
						is always clear, useful and perfectly tailored to your business.
					</p>
					<p className="pb-2">
						Simply call{' '}
						<a
							className="mb-3 text-xl font-bold"
							href={`tel:${SUPPORT_PHONE}`}
							onClick={() => ga('Call advice')}
						>
							{SUPPORT_PHONE}
						</a>
					</p>
				</div>
				<img className="md:w-2/5" src={ExpertAdviceImage} alt="a desktop with computer" />
			</div>
		</div>
	);
};

export default MyVaccTrak;
