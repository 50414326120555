import React from 'react';
import ga from 'utils/ga/myEmployees';
import { VaccineStatus, VaccineInfo } from '../../components/VaccineFields';
import FormikInput from '../../../components/FormikInput';

export default ({ formik, index }) => {
	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 my-2">
				<div className="my-2 lg:my-0">
					<div>First name</div>
					<FormikInput
						name={`firstName${index}`}
						placeholder="First name"
						onFocus={() => ga('First name')}
					/>
				</div>
				<div className="my-2 lg:my-0">
					<div>Last name</div>
					<FormikInput
						name={`lastName${index}`}
						placeholder="Last name"
						onFocus={() => ga('Last name')}
					/>
				</div>
				<div className="my-2 lg:my-0">
					<div>Email</div>
					<FormikInput
						name={`email${index}`}
						placeholder="Email"
						onFocus={() => ga('Email')}
					/>
				</div>
				<div className="my-2 lg:my-0">
					<div>COVID-19 vaccinated?</div>
					<VaccineStatus formik={formik} index={index} ga={ga} />
				</div>
			</div>

			<div className="w-full lg:w-2/3">
				<VaccineInfo formik={formik} index={index} ga={ga} />
			</div>
		</>
	);
};
