import http from 'utils/http';
import config from 'utils/config';

const deleteEmployee = (guid) => {
	const { GLOBAL_API } = config();
	const path = `${GLOBAL_API}employee/${guid}`;

	return http.delete(path);
};

export default deleteEmployee;
