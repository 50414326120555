import React from 'react';
import config from 'utils/config';
import { isANZ } from 'utils/translateHelpers';

const { SUPPORT_PHONE } = config();

export default ({ ga }) => (
	<div className="w-full p-4 bg-brand-100 rounded mt-8 md:w-2/5 md:mt-0">
		<a
			className="font-bold text-xl text-secondary-300 mb-3"
			href={`tel:${SUPPORT_PHONE}`}
			onClick={() => ga('Call advice')}
		>
			Get FREE {isANZ() ? 'initial' : 'expert'} advice {SUPPORT_PHONE}
		</a>
		<p>Need help getting your staff back to work safely?</p>
		<p>
			Call our {isANZ() ? 'team' : 'experts'} <b>Monday to Friday, 9am to 5pm</b>, to get
			instant answers to all your HR and health & safety queries.
		</p>
	</div>
);
