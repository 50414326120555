import React from 'react';
import ga from 'utils/ga/hub';
import { isUk, isANZ } from 'utils/translateHelpers';
import config from 'utils/config';

const { SUPPORT_PHONE, WEBINAR } = config();

export default () => (
	<div
		className="mt-3 md:mt-0 md:w-1/3 lg:w-1/4 md:ml-4 rounded-md"
		style={{ background: 'rgba(255,255,255,0.9)' }}
	>
		<div className="p-4">
			<h4 className="font-bold text-xl text-secondary-300">
				Get FREE {isANZ() ? 'initial' : 'expert'} advice
			</h4>
			<a
				className="font-bold text-xl text-secondary-300 mb-3"
				href={`tel:${SUPPORT_PHONE}`}
				onClick={() => ga('Call advice')}
			>
				{SUPPORT_PHONE}
			</a>
			<p>
				Need help getting your staff back to work safely? Call our{' '}
				{isANZ() ? 'team' : 'experts'} <b>Monday to Friday, 9am to 5pm</b>, to get instant
				answers to all your HR and health & safety queries.
			</p>
			{(isUk() || isANZ()) && (
				<>
					<hr className="my-6 border-brand-300" />
					<h4 className="font-bold mb-1 text-lg">Watch our helpful webinar</h4>
					<h5 className="font-bold mb-1">
						Vaccination against COVID-19: Employer considerations
					</h5>
					<p className="mb-1">
						Join our HR experts for an online training session on the COVID-19 vaccine.
						Learn how to speak to your staff about the vaccine and handle any
						complicated questions.
					</p>
					<a
						className="text-brand-500 font-bold"
						target="_blank"
						rel="noreferrer noopener"
						href={WEBINAR}
						onClick={() => ga('Watch webinar now')}
					>
						Watch webinar now
					</a>
				</>
			)}
			{!isANZ() && (
				<>
					<hr className="my-6 border-brand-300" />
					<h4 className="font-bold mb-1 text-lg">Access essential resources</h4>
					<p className="mb-1">
						Head to our resource library to download complimentary policy and letter
						templates. Plus, discover a set of educational videos on the vaccine.
					</p>
					<a
						className="text-brand-500 font-bold"
						href="#essential-resources"
						onClick={() => ga('Find out more')}
					>
						Find out more
					</a>
				</>
			)}
		</div>
	</div>
);
