import jwtDecode from 'jwt-decode';

let decodedToken;

export default () => {
	if (!window.localStorage.getItem('userToken')) {
		return {};
	}

	if (!decodedToken) {
		decodedToken = jwtDecode(window.localStorage.getItem('userToken'));
	}

	return decodedToken;
};
