export const localeObject = {
	en: {
		countryCode: 'en',
		nameShort: 'UK',
		name: 'United Kingdom',
	},
	ca: {
		countryCode: 'ca',
		nameShort: 'CA',
		name: 'Canada',
	},
	au: {
		countryCode: 'au',
		nameShort: 'AU',
		name: 'Australia',
	},
	nz: {
		countryCode: 'nz',
		nameShort: 'NZ',
		name: 'New Zealand',
	},
	ie: {
		countryCode: 'ie',
		nameShort: 'IE',
		name: 'Ireland',
	},
};

export const localeRegex = /\/(en|au|nz|ie|ca)(?:\/|$)/i;

export const setNewLocale = (newLocale, refresh = true) => {
	localStorage.setItem('preferredLocale', newLocale);

	if (refresh)
		window.location.assign(
			`/${newLocale === 'en' ? '' : `${newLocale}/`}${window.location.search}`
		);
};

const pathLocale = () => {
	const matches = window.location.pathname.match(localeRegex);
	const map =
		matches &&
		Object.values(localeObject).find(({ countryCode }) => countryCode === matches[1]);

	return map ? map.countryCode : null;
};
export const lsLocale = localStorage.getItem('preferredLocale');
export const currentLocale = localeObject[pathLocale() || lsLocale || 'en'];

export default { localeObject, setNewLocale, lsLocale, currentLocale };
