import http from 'utils/http';
import config from 'utils/config';

const pollReport = (location) =>
	new Promise((resolve, reject) => {
		const apiUrL = config().API_URL?.slice(0, -1);
		const checkStatusOfReport = () =>
			http.get(apiUrL + location).then(({ data: { status, _links } }) => {
				if (status === 'Pending') {
					return setTimeout(checkStatusOfReport, 5000);
				}

				if (status === 'Complete') {
					const { href } = _links['http://api.brighthr.com/rels/download'];

					return resolve(
						http.get(apiUrL + href, {
							responseType: 'arraybuffer',
						})
					);
				}

				return reject(new Error('failed to generate report'));
			});

		checkStatusOfReport();
	});

export default function generateReport(data) {
	const { API_URL } = config();

	const url = `${API_URL}v1/report/reports`;

	return http
		.post(url, {
			data,
		})
		.then((response) => {
			const { location } = response.headers;
			return pollReport(location, data);
		});
}
