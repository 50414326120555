import http from 'utils/http';
import config from 'utils/config';

const sendPass = (guid) => {
	const { API_URL } = config();
	const path = `${API_URL}employee/${guid}/covidvaccination/pass`;

	return http.post(path).then(({ data }) => data);
};

export default sendPass;
