import getToken from 'utils/getToken';

export default (environment = 'sandbox') => {
	const { locale = 'en-gb' } = getToken();

	const getEnv = (config) => {
		const country = locale.slice(3);
		const firstLevel = config[country.toLowerCase()] || config[environment] || config.default;
		return firstLevel[environment] || firstLevel.default || firstLevel;
	};

	return {
		GLOBAL_API: getEnv({
			default: 'https://sandbox-api.brighthr.com/v1/',
			prod: 'https://api.brighthr.com/v1/',
		}),

		BRIGHT_HR_LINK: getEnv({
			default: 'https://sandbox-app.brighthr.com',
			prod: 'https://app.brighthr.com',
			dev: 'http://localhost:9000',
		}),

		AUTHORISATION_SERVER_URL: getEnv({
			prod: 'https://login.brighthr.com',
			default: 'https://sandbox-login.brighthr.com',
		}),
		GA4_TRACKING_ID: getEnv({
			prod: 'G-KXS56V2E9X',
			default: '',
		}),
		GA_TRACKING_ID: getEnv({
			prod: 'UA-67739736-14',
			default: '',
		}),

		SHOW_REAL_LANDING_PAGE: getEnv({
			prod: false,
			default: true,
		}),

		API_URL: getEnv({
			gb: {
				prod: 'https://api.brighthr.com/',
				default: 'https://sandbox-api.brighthr.com/',
			},
			ca: {
				sandbox: 'https://sandbox-api.brighthr.ca/',
				prod: 'https://api.brighthr.ca/',
			},
			ie: {
				sandbox: 'https://sandbox-api.brighthr.ie/',
				prod: 'https://api.brighthr.ie/',
			},
			au: {
				sandbox: 'https://sandbox-api.brighthr.com.au/',
				prod: 'https://api.brighthr.com.au/',
			},
			nz: {
				sandbox: 'https://sandbox-api.brighthr.com.au/',
				prod: 'https://api.brighthr.com.au/',
			},
		}),

		SUPPORT_PHONE: getEnv({
			gb: '0800 028 9874',
			ca: '+1 (833) 247-3658',
			ie: '1800 313 658',
			au: '1300 641 720',
			nz: '0800 675 707',
		}),

		E_LEARNING_COURSE: getEnv({
			gb: 'https://elearning.brighthr.com/brighthr/en/coronavirus-vaccine-awareness',
			ca: 'https://elearning.brighthr.com/brighthr/ca/coronavirus-vaccine-awareness',
			ie: 'https://elearning.brighthr.com/brighthr/en/coronavirus-vaccine-awareness',
			au: 'https://elearning.brighthr.com/brighthr/au/coronavirus-vaccine-awareness',
			nz: 'https://elearning.brighthr.com/brighthr/nz/coronavirus-vaccine-awareness',
		}),

		SUPPORT_GUIDE: getEnv({
			gb: 'https://elearning.brighthr.com/brighthr/en/vacctrak-lite-support-guide/index.html',
			ca: 'https://elearning.brighthr.com/brighthr/ca/vacctrak-lite-support-guide/index.html',
			ie: 'https://elearning.brighthr.com/brighthr/ie/vacctrak-lite-support-guide/index.html',
			au: 'https://elearning.brighthr.com/brighthr/au/vacctrak-lite-support-guide/index.html',
			nz: 'https://elearning.brighthr.com/brighthr/nz/vacctrak-lite-support-guide/index.html',
		}),

		WEBINAR: getEnv({
			gb: 'https://pages.brighthr.com/VaccTrakWebinar.html',
			ca: '/',
			ie: '/',
			au: 'https://employsure.com.au/webinars/covid-19-vaccinations-employer-fundamentals',
			nz: 'https://employsure.co.nz/webinars/covid-19-vaccinations-employer-fundamentals',
		}),
		VACC_CONSIDERATIONS: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/UK_Coronavirus_vaccine_and_your_employees_HR_considerations.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Coronavirus_Vaccine_and_Your_Employees_HR_Considerations.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/ROI_Coronavirus_vaccine_and_your_employees_HR_considerations.pdf',
			au: '/',
			nz: '/',
		}),

		ENCOURAGING_STAFF: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/UK_Letter_to_employees_encouraging_uptake_of_coronavirus_vaccine.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Letter_to_Employees_Encouraging_Uptake_of_Coronavirus_Vaccine.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/ROI_Letter_to_employees_encouraging_uptake_of_coronavirus_vaccine.pdf',
			au: '/',
			nz: '/',
		}),

		REQUIRING_STAFF: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/UK_Letter_to_employees_requiring_uptake_of_coronavirus_vaccine.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Letter_to_Employees_Requiring_Uptake_of_Coronavirus_Vaccine.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/ROI_Letter_to_employees_requiring_uptake_of_coronavirus_vaccine.pdf',
			au: '/',
			nz: '/',
		}),

		VACC_POLICY: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/UK_Coronavirus_vaccine_policy.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Coronavirus_Vaccine_Policy.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/ROI_Coronavirus_Vaccine_Policy.pdf',
			au: '/',
			nz: '/',
		}),

		BACK_TO_WORK: getEnv({
			gb: 'https://www.brighthr.com/media/113377/back-to-work-hygiene-guidance-note-v1.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Back_To_Work_Hygiene_Guidance_Note.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/Back_to_Work_Hygiene_Guidance_Note.pdf',
			au: '/',
			nz: '/',
		}),

		HYGIENE_CHECKLIST: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/Back_to_Work_Hygiene_Checklist_v1.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_Back_To_Work_Hygiene_Checklist.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/Back_to_Work_Hygiene_Checklist.pdf',
			au: '/',
			nz: '/',
		}),

		HAND_HYGIENE: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/VaccTrak_UK_Hand_Hygiene_Poster.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_COVID-19_Hand_Hygiene_Poster.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/ROI_COVID-19_Hand_Hygiene_Poster.pdf',
			au:
				'https://pages.brighthr.com/rs/217-MIC-854/images/AUS_Coronavirus_hand_hygiene_poster.pdf',
			nz:
				'https://pages.brighthr.com/rs/217-MIC-854/images/NZ_Coronavirus_hand_hygiene_poster.pdf',
		}),

		MASK_FITTING: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/VaccTrak_Coronavirus_Mask_Fitting_Poster.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_COVID-19_Mask_Fitting_Poster.pdf',
			ie: 'https://pages.brighthr.com/rs/217-MIC-854/images/COVID19_Mask_Fitting_Poster.pdf',
			au:
				'https://pages.brighthr.com/rs/217-MIC-854/images/AUS_Coronavirus_Mask_Fitting_Poster.pdf',
			nz:
				'https://pages.brighthr.com/rs/217-MIC-854/images/NZ_Coronavirus_Mask_Fitting_Poster.pdf',
		}),

		SOCIAL_DISTANCING: getEnv({
			gb:
				'https://pages.brighthr.com/rs/217-MIC-854/images/VaccTrak_Social_Distancing_Generic.pdf',
			ca:
				'https://pages.brighthr.com/rs/217-MIC-854/images/CA_COVID-19_Social_Distancing_Poster.pdf',
			ie:
				'https://pages.brighthr.com/rs/217-MIC-854/images/COVID19_Social_Distancing_Poster.pdf',
			au: 'https://pages.brighthr.com/rs/217-MIC-854/images/AUS_Social_distancing.pdf',
			nz: 'https://pages.brighthr.com/rs/217-MIC-854/images/NZ_Social_distancing.pdf',
		}),
	};
};
