import http from 'utils/http';
import config from 'utils/config';

const updateMedicalInformation = ({
	employeeGuid,
	vaccinationStatus,
	vaccinationDate,
	firstVaccinationDate,
	firstVaccinationSeenProof,
	finalVaccinationSeenProof,
	boosterVaccinationDate,
	boosterVaccinationSeenProof,
	secondBoosterDate,
	secondBoosterVaccinationSeenProof,
	notes,
}) => {
	const { API_URL } = config();
	const negativeStatuses = ['no', 'not specified', 'exempt', 'prefer not to say'];
	const payload = negativeStatuses.includes(vaccinationStatus.toLowerCase())
		? {
				vaccinationStatus,
				notes,
				vaccinationDate: null,
				firstVaccinationDate: null,
				firstVaccinationSeenProof: false,
				finalVaccinationSeenProof: false,
				boosterVaccinationDate: null,
				boosterVaccinationSeenProof: false,
				secondBoosterDate: null,
				secondBoosterVaccinationSeenProof: false,
		  }
		: {
				vaccinationStatus,
				vaccinationDate,
				firstVaccinationDate,
				firstVaccinationSeenProof,
				finalVaccinationSeenProof,
				boosterVaccinationDate,
				boosterVaccinationSeenProof,
				secondBoosterDate,
				secondBoosterVaccinationSeenProof,
				notes,
		  };

	const path = `${API_URL}employee/${employeeGuid}/covidvaccination`;

	return http.put(path, { data: payload });
};

export default updateMedicalInformation;
