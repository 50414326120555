import React from 'react';
import styled from 'styled-components';
import colors from 'bright-components/dist/constants/colors';
import spacing from 'bright-components/dist/constants/spacing';
import Chevron from 'bright-components/dist/components/Chevron/Chevron';

const Expandable = styled.div`
	margin-bottom: ${spacing.s1};
`;

const Header = styled.div`
	background: ${colors.greyLighter};
	padding: ${spacing.s1};
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
`;
Header.displayName = 'Header';

const HeaderContent = styled.div`
	display: flex;
	align-items: center;

	svg {
		margin-left: ${spacing.s1};
	}
`;

const Content = styled.div``;

const ExpandoBar = ({ onToggle, expanded, children, title, icon }) => (
	<Expandable>
		<Header onClick={onToggle}>
			<HeaderContent>
				{title}
				{icon}
			</HeaderContent>
			<Chevron expanded={expanded} />
		</Header>
		{expanded && <Content>{children}</Content>}
	</Expandable>
);

export default ExpandoBar;
